import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import {BigNumber} from '@ethersproject/bignumber';
import AddIcon from '@mui/icons-material/Add';
import PostFeedItem from '../post-feed-item';

import { getPostIndex, getPostUri } from '../../service/worksManager';
import { fetchMetadata, buildQueueRow } from "../../service/utility";

function PostFeedList({contractAddress, setPost, store}) {
  const [postMetadata, setPostMetadata] = useState([]);
  const [postIndex, setPostIndex] = useState(BigNumber.from(0));  
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    //setPostMetadata([]);
    //setPostMap({});
    const getPostList = async () => {
      if (contractAddress !== '') {
        console.log(contractAddress);
        const pI = await getPostIndex(contractAddress);
        if (componentIsMounted.current) setPostIndex(pI);
      }      
    } 
    getPostList();
  }, [contractAddress]);

  useEffect(() => {
    //setPostMetadata([]);
    //setPostMap({});
    const getPostList = async () => {
      try {
        let pIndex = postIndex.toNumber();
        let postList = [];
        for (let i = 0; i < pIndex; i++) {
          let indexNum = BigNumber.from(i);
          let postMetadataUri = await getPostUri(contractAddress, indexNum);
          if (postMetadataUri && postMetadataUri.length > 0) {
            let postMetadataResponse = await fetchMetadata(indexNum, postMetadataUri);
            postList.push(postMetadataResponse);
          }
        }
        if (componentIsMounted.current) setPostMetadata(postList);
      } catch (e) {
        console.log(e);
      }     
    } 
    getPostList();
  },[postIndex, contractAddress]);

  const refreshList = async () => {
    setPostIndex(BigNumber.from(0));
    const pI = await getPostIndex(contractAddress);
    if (componentIsMounted.current) setPostIndex(pI);
  }

  const listOfPosts = () => (
    <div style={{display: 'contents'}}>
      { 
        postMetadata.map((post) => {
          return (
            <div key={post.id} style={{display: 'contents'}}>
              <PostFeedItem itemMetadata={post} postId={post.id} channelId={post.channelId} contractAddress={contractAddress} store={store}></PostFeedItem>  
            </div>
          );
        })        
      }
    </div>
  );

  return (
    <Box className="" sx={{ maxWidth: '50vw'}}>
      {listOfPosts()}
    </Box>
  );
}

export default PostFeedList;