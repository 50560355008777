
import { v4 as uuidv4 } from 'uuid';
const API_URL = 'https://www.buzzsprout.com/api';

export const getPodcastList = async function(key) {
  const httpOptions = {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token token='+key
    }
  };
  //console.log(httpOptions);
  const response = await fetch(`${API_URL}/podcasts.json?api_token=${key}`, httpOptions);
  //const responseText = await response.text();
  //console.log(responseText);
  const responseData = await response.json();
  return responseData;
}

export const buildChannelRow = function(buzzsprout) {
  const today = new Date();
  let channelRow = {
    id :  uuidv4(),
    name : buzzsprout.title,
    description: buzzsprout.description || '',
    properties: {
      createdDate: today.toUTCString(),
      author : buzzsprout.author,
      language: buzzsprout.language,
      copyright: buzzsprout.author,
      website: buzzsprout.website_address || '',
      instagramUrl: "",
      type: 'api', 
      buzzsproutId: buzzsprout.id
    },
    image: buzzsprout.artwork_url,
  };

  return channelRow;
}