
import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { getChannelMetadata } from '../../service/worksManager';
import {fetchMetadata} from '../../service/utility';
function MessageFeedItem(input) {
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  const [channelLink, setChannelLink] = useState("");
  const [channelMetadata, setChannelMetadata] = useState({});

  const getChannel = async () => {
    try {
      let metadataUri = await getChannelMetadata(input?.channelId);
      if (metadataUri) {
        let metadataResponse = await fetchMetadata(input?.channelId, metadataUri);
        
        if (componentIsMounted.current) {
          setChannelMetadata(metadataResponse);
          setChannelLink('/channels/'+input?.channelId);
        }
      }
    } catch (calloutError) {
      console.log(calloutError);
    }
    
  };

  useEffect(() => {
    getChannel();
  }, [input.channelId]);

  const Image = styled('img')({
    "maxWidth": "85px", width: "-webkit-fill-available", borderRadius:'1rem'
  });

  return (
    <div style={{display: 'contents'}}>
      {channelMetadata !== {} && 
        <Button component={Link} to={channelLink} sx={{textTransform:'none',backgroundColor: 'transparent !important'}}>
          <Box maxWidth="lg" sx={{mx: "auto", mb: 16, display: 'flex'}}>
            <Box sx={{m:8, display:'inline-block'}}>
              <Image src={channelMetadata?.parse_image} alt=''/>
              <Typography noWrap variant="h6" gutterBottom component="div" sx={{color: 'text.primary', my:0, fontSize:"80%"}}>{channelMetadata?.name}</Typography> 
              <Typography noWrap variant="caption" display="block" gutterBottom sx={{color: 'text.secondary'}}>{channelMetadata?.properties?.author}</Typography> 
            </Box>
            <Box sx={{m:8, display:'inline-block'}}> 
              <Typography noWrap variant="body" display="block" gutterBottom sx={{color: 'text.secondary', fontSize: '80%'}}>{input.messageData.createdDate}</Typography> 
              <Typography noWrap variant="body" display="block" gutterBottom sx={{color: 'text.primary'}}>{input.messageData.message}</Typography> 
            </Box>
          </Box>
        </Button>
      }
    </div>
    
  );
}

export default MessageFeedItem;