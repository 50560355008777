import React, { useEffect, useState, useRef } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import { Typography } from "@mui/material";
import {BigNumber} from 'ethers';
import {
  getProfileEvents,
  getChannelEvents
} from '../../service/worksManager';
import ChannelTable from '../channel-table';
import ArtistTable from "../artist-table";

function SearchInput() {
  const currentTheme = useTheme();
  const componentIsMounted = useRef(true);
  const [searchVal, setSearchVal] = useState('');
  function onSearchChange(event) {
    setSearchVal(event.target.value);
  }
  
  const [modalOpen, setModalOpen] = React.useState(false);

  const [channelEventList, setChannelEventList] = useState([]);
  const [profileEventList, setProfileEventList] = useState([]);
  
  function closeSearchModal() {
    setModalOpen(false);
  }
  function openSearchModal() {
    setModalOpen(true);
  }

  const searchChannels = async () => {
    try {
      let currentEvents = await getChannelEvents();
      let channelList = [];
      let channelCompare = [];
      if (currentEvents) {
        for (let event of currentEvents) {
          if (!channelCompare.includes(event.channelId) && event.keywords.toLowerCase().includes(searchVal.toLowerCase())) {
            const event2 = {
              channelId: BigNumber.from(event.channelId),
              id: event.channelId
            };   
            channelCompare.push(event2.channelId);
            channelList.push(event2);
          }
                   
        }
        
        if (componentIsMounted.current) setChannelEventList(channelList)
      }
      return null;
    } catch (calloutError) {}
    
  };

  const searchProfiles = async () => {
    try {
      let currentEvents = await getProfileEvents();
      let artistList = [];
      let artistCompare = [];
      if (currentEvents) {
        for (let event of currentEvents) {
          if (!artistCompare.includes(event.sender) && event.keywords.toLowerCase().includes(searchVal.toLowerCase())) {
            const event2 = {
              sender: event.sender,
              id: event.sender
            };   
            artistCompare.push(event2.sender);
            artistList.push(event2);
          }
                   
        }
        
        if (componentIsMounted.current) setProfileEventList(artistList)
      }
    } catch (calloutError) {}
  };

  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    let asynFunction = async () => {
      if (searchVal.length > 0) {
        await searchChannels();
        await searchProfiles();
      }
    }
    
    asynFunction();
  }, [searchVal]);

  return (
    <>
      <Paper
        component="form"
        elevation={0}
        sx={{ 
          p: '2px 4px', display: 'flex', maxWidth: '25vw'
          , borderRadius: '10rem', 
          bgcolor: currentTheme.palette.grey[200],
          ...(currentTheme.palette.mode === 'dark' && {
            backgroundColor: currentTheme.palette.grey[700],
          })
        }}
      >
        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" onClick={openSearchModal}>
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          onChange={onSearchChange}
          inputProps={{ 'aria-label': 'search database' }}
        />
      </Paper>
      <Dialog open={modalOpen} onClose={closeSearchModal} maxWidth="xl">
        <DialogTitle>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSearchModal}
            sx={{float:'right'}}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent >
          <Card className="transparent-background" sx={{width: "50vw"}}>
            <Typography variant="h6" gutterBottom component="div" sx={{color: 'text.primary', my:0, py:6}}>Channels</Typography> 
            <ChannelTable channels={channelEventList}></ChannelTable>
            <Typography variant="h6" gutterBottom component="div" sx={{color: 'text.primary', my:0, py:6}}>Artists</Typography> 
            <ArtistTable artists={profileEventList}></ArtistTable>
          </Card>
        </DialogContent>
      </Dialog>
      
    </>
  );
}

export default SearchInput;


