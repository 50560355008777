import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

function Homepage() {
  return (
    <div style={{ height: '90vh'}}>
        <Box sx={{
          width: "30vw",
          paddingLeft: "10vw",
          textAlign: "center",
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: 'space-between',
          paddingTop: '40vh',
          transform: 'scale(1.5,1)'
        }}>
          <Typography variant="h2" display="div" sx={{fontSize: '6rem', fontWeight: 'bold', color: 'text.primary', textTransform: 'uppercase'}}>T</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: '6rem', fontWeight: 'bold', color: 'text.primary', textTransform: 'uppercase'}}>h</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: '6rem', fontWeight: 'bold', color: 'text.primary', textTransform: 'uppercase'}}>e</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: '6rem', fontWeight: 'bold', color: 'text.primary', textTransform: 'uppercase'}}> </Typography>
          <Typography variant="h2" display="div" sx={{fontSize: '6rem', fontWeight: 'bold', color: 'text.primary', textTransform: 'uppercase'}}>N</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: '6rem', fontWeight: 'bold', color: 'text.primary', textTransform: 'uppercase'}}>e</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: '6rem', fontWeight: 'bold', color: 'text.primary', textTransform: 'uppercase'}}>x</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: '6rem', fontWeight: 'bold', color: 'text.primary', textTransform: 'uppercase'}}>t</Typography> 
        </Box>
        <Box sx={{
          width: "30vw",
          paddingLeft: "10vw",
          textAlign: "center",
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: 'space-between',
          transform: 'scale(1.5,1)'
        }}>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}>G</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}>e</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}>n</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}>e</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}>r</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}>a</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}>t</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}>i</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}>o</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}>n</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}> </Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}>O</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "2rem", fontWeight: 'bold',color: 'text.primary', textTransform: 'uppercase'}}>f</Typography>
        </Box>
        <Box sx={{
          width: "30vw",
          paddingLeft: "10vw",
          textAlign: "center",
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: 'space-between',
          transform: 'scale(1.5,1)'
        }}>
          <Typography variant="h2" display="div" sx={{fontSize: "10rem", fontWeight: '900',color: 'text.primary', textTransform: 'uppercase'}}>R</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "10rem", fontWeight: '900',color: 'text.primary', textTransform: 'uppercase'}}>S</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "10rem", fontWeight: '900',color: 'text.primary', textTransform: 'uppercase'}}>S</Typography>
          <Typography variant="h2" display="div" sx={{fontSize: "10rem", fontWeight: '900',color: 'text.primary', textTransform: 'uppercase'}}>.</Typography>
        </Box>
        <Box sx={{
          width: "30vw",
          paddingLeft: "10vw",
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: 'center',
          transform: 'scale(1.5,1)'
        }}>
          <Typography variant="h4" display="div" sx={{fontSize: '1.2rem',fontWeight: 'light',color: 'text.primary'}}>A Web3 Standard for Podcast Syndication</Typography>
        </Box>
        
    </div>
  );
}

export default Homepage;
