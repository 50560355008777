
import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BigNumber } from 'ethers';
import moment from 'moment';
import {parseStream} from 'music-metadata/lib/core';

import { getChannelMetadata } from '../../service/worksManager';
import { cleanImageUrl, ipfsGateway } from '../../service/utility';


function PostItemDuration({uri}) {
  const [duration, setDuration] = useState(0); 
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    const getMetadata = async () => {
      if (uri) {
        try {
          fetch(uri).then(async (response) => {
            console.log(response.body);
            const parseMetadata = await parseStream(new ReadableStream(response.body), {}, {duration: true});
            const _duration = moment.duration(parseMetadata.format.duration, 'seconds').as('minutes');
            if (componentIsMounted.current) setDuration(_duration);
            console.log(_duration)
          });
          
        } catch (e) {
          console.log(e);
        }
        
      }
    } 
    if (componentIsMounted.current && uri) {
      getMetadata();
    }
  }, [uri]);



  return (
    <>
      {duration != 0 && 
        <>{duration}</>
      }
    </>
  );
}

export default PostItemDuration;

/*
<Box maxWidth="lg" sx={{mx: "auto", mb: 16, display: 'flex'}}>
  <Box sx={{m:8, display:'inline-block'}}>
    <Image src={channelMetadata?.parse_image} alt=''/>
    <Typography noWrap variant="h6" gutterBottom component="div" sx={{color: 'text.primary', my:0, fontSize:"80%"}}>{channelMetadata?.name}</Typography> 
    <Typography noWrap variant="caption" display="block" gutterBottom sx={{color: 'text.secondary'}}>{channelMetadata?.properties?.author}</Typography> 
  </Box>
</Box>
*/