import React, {useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createReceptionSVG, cleanImageUrl, truncateString, ipfsGateway, buildQueueRow } from '../../service/utility';
import ReactPlayer from 'react-player';
import 'plyr-react/dist/plyr.css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import QueueFeedList from '../queue-feed-list';
 
function Footer({store, openDrawer}) {
  const [selectedMedia, setSelectedMedia] = React.useState('');
  //const [selectedMediaType, setSelectedMediaType] = React.useState('');
  //const [selectedMediaMime, setSelectedMediaMime] = React.useState('');
  //const [selectedMediaData, setSelectedMediaData] = React.useState({});
  //const [mediaHeight, setMediaHeight] = React.useState(20);
  const [currentCount, setCurrentCount] = React.useState(0);
  const [queueList, setQueueList] = React.useState([]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isMediaAudio, setIsMediaAudio] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [ currentMetadata, setCurrentMetadata] = React.useState({});
  const [ queue, setState ] = store.useState('queue');
  const [ currentItem, setCurrentItem ] = store.useState('currentItem');
  
  const theme = useTheme();
  store.getState('queue').subscribe((val) =>{
    // value is the new value of a global state
    console.log('queue updated');
    if (val.length > 0) {
      setQueueList(val);
      setStatus(true);
      if (selectedMedia.length === 0) {
        const qFile = val[0].file;
        setSelectedMedia(qFile);
        setDrawerOpen(true);
        setCurrentCount(0);
        setCurrentItem(val[0].key);
        setCurrentMetadata(val[0]);
        if (qFile.endsWith('m4a') || qFile.endsWith('mp3')) {
          setIsMediaAudio(true);
        } else {
          setIsMediaAudio(false);
        }
      }
    }
  });

  useEffect(() => {
    if (openDrawer) {
      setDrawerOpen(true)
    }
  }, [openDrawer])

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
    if (!open) {
      //clearPost();
    }
    
  };

  const playNext = () => {
    if (queueList.length >= (currentCount+1)) {
      const qFile = queueList[currentCount+1].file;
      setSelectedMedia(qFile);
      setCurrentCount(currentCount+1);
      setCurrentItem(queueList[currentCount+1].key);
      setCurrentMetadata(queueList[currentCount+1]);
      if (qFile.endsWith('m4a') || qFile.endsWith('mp3')) {
        setIsMediaAudio(true);
      } else {
        setIsMediaAudio(false);
      }
    }
  }

  const playPrevious = () => {
    if (currentCount > 0) {
      const qFile = queueList[currentCount-1].file;
      setSelectedMedia(qFile);
      setCurrentCount(currentCount-1);
      setCurrentItem(queueList[currentCount-1].key);
      setCurrentMetadata(queueList[currentCount-1]);
      if (qFile.endsWith('m4a') || qFile.endsWith('mp3')) {
        setIsMediaAudio(true);
      } else {
        setIsMediaAudio(false);
      }
    }
  }

  const exitButton = () => {
    return (
      <IconButton onClick={toggleDrawer(false)} variant="contained" color="primary" sx={{display: 'inline-block', float: 'right', padding: 2, fontSize: 'small'}} aria-label="delete">
        <CloseIcon fontSize="inherit" />
      </IconButton>
    );
  }

  const trackDetails = () => {
    return (
      <Typography variant="body2" gutterBottom component="div" sx={{color: 'text.primary', mt:1,mb:0, mx:15, lineHeight: 2.7}}>{currentMetadata.name}</Typography>
    );
  }

  return (
    <div style={{overflowX: 'hidden', overflowY: 'hidden'}}>
      { 
        status === true && 
        <Drawer
          anchor={'bottom'}
          open={drawerOpen}
          sx={{height: 5, overflowX: 'hidden', overflowY: 'hidden'}}
          onClose={toggleDrawer(false)}
          variant="persistent"
          className="footer-area"
        >
          <Grid container spacing={2} sx={{overflowX: 'hidden', overflowY: 'hidden'}}>
            {selectedMedia !== '' && 
              <Grid container spacing={2} sx={{overflowX: 'hidden', overflowY: 'hidden'}}>
                <Grid item xs={12} sx={{overflowX: 'hidden', overflowY: 'hidden'}}>
                  <Paper elevation={4} sx={{boxShadow: 'none', overflowX: 'hidden', overflowY: 'hidden'}}>
                    <Accordion
                      sx={{
                        overflow: 'hidden'
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="expandQueue"
                        id="expandQueue-header"
                        sx={{
                          overflow: 'hidden',
                          my:0,
                          minHeight: 'auto',
                          backgroundColor: theme.palette.background.default,
                          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))',
                        }}
                      >
                        {
                          exitButton()
                        }
                        {
                          trackDetails()
                        }
                      </AccordionSummary>
                      <AccordionDetails 
                        sx={{
                          backgroundColor: theme.palette.background.default,
                          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))',
                        }}
                      >
                        <Grid container spacing={2}>
                          {/*<Grid item xs={4}>
                            <QueueFeedList currentItem={currentItem} queueList={queueList}></QueueFeedList>
                          </Grid>*/}
                          <Grid item xs={12}>
                            {
                              !isMediaAudio && 
                              <ReactPlayer url={selectedMedia} controls={true} width="100%"  playing={false}/>
                            }
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    
                  </Paper>
                  <AudioPlayer
                    autoPlay={false}
                    src={selectedMedia}
                    style={{
                      width: '100%',
                      backgroundColor: theme.palette.background.default,
                      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))',
                      color: theme.palette.getContrastText(theme.palette.background.default), 
                      boxShadow: 'none'
                    }}
                    customAdditionalControls={['LOOP']}
                    onClickNext={playNext}
                    onClickPrevious={playPrevious}
                    showSkipControls={true}
                  />
                </Grid>
              </Grid>
            }            
          </Grid>
        </Drawer>
      }
    </div>
  );
}

export default Footer;

/*
<Plyr
  source={selectedMediaData}
/>

<ReactPlayer url={selectedMedia} light="false"/>
          
{
  !isMediaAudio && 
  //<ReactPlayer url={selectedMedia} controls={true} width="100%" playing="true"
  />
}
*/