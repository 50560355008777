import React, {useEffect} from 'react';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import QueueFeedItem from '../queue-feed-item';

function QueueFeedList({store, type}) {
  const theme = useTheme();  
  const [currentItem, setCurrentItem] = store.useState('currentItem');  
  const [ queue, setState ] = store.useState('queue');
  
  const listOfQueueItems = () => (
    <div style={{display: 'contents'}}>
      { 
        queue.map((item) => {
          return (
            <div key={item.key} style={{display: 'contents'}}>
              <QueueFeedItem item={item} selectedItem={currentItem}></QueueFeedItem>  
            </div>
          );
        })        
      }
    </div>
  );
  return (
    <>      
      <Box sx={{ maxWidth: '50vw' }}>
        {type === 'all' &&         
          <Typography variant="h4" gutterBottom component="div" sx={{padding:10, color: theme.palette.text.primary, }}>Queue</Typography>
        }
        {queue.length > 0 && 
          <Stack spacing={1}>
            {listOfQueueItems()}
          </Stack>          
        }
      </Box>
    </>   
  );
}

export default QueueFeedList;