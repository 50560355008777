import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { useContractCall } from "@usedapp/core";
import {ethers} from 'ethers';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import matic_logo from '../../static/images/matic.png';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEthers } from '@usedapp/core';

function TierTable({store, setTiers, setIsSelected}) {
  const { channelId } = useParams();
  const { account, chainId, library } = useEthers();
  const theme = useTheme();
  const apiRef = useGridApiRef();
  const [validTiers, setValidTiers] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [channelTiers, setChannelTiers] = useState([]);
  const [channelMembershipMetadata, setChannelMembershipMetadata] = useState([]);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  
  
  const columns = [
    {
      field: 'name',
      headerName: 'Level Name',
      flex: 3,
      type: 'string',
      editable: true,
      sortable: true,
      preProcessEditCellProps: (params) => {
        const hasError = params.props.value.length < 3;
        //console.log('name validation',params, hasError);
        return { ...params.props, error: hasError };
      },
    },
    {
      field: 'amount',
      headerName: 'Total to Mint',
      flex: 6,
      type: 'number',
      editable: true,
      sortable: true,
      preProcessEditCellProps: (params) => {
        const isInteger = Number.isInteger(parseFloat(params.props.value));
        const hasError = !isInteger || parseInt(params.props.value) <= 0;
        //console.log('amount validation',params, hasError, parseInt(params.props.value) >= 0);
        return { ...params.props, error: hasError };
      }
    },
    {
      field: 'cost',
      headerName: 'Cost',
      flex: 3,
      type: 'number',
      cellClassName: 'cost-cell',
      editable: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
              <Typography variant="body2" color="text.secondary" sx={{}}>
                {params.row.cost} <img src={matic_logo} alt="MATIC" className="icon-image"/>
              </Typography>
            
          </>
        )
      }
    }
  ];

  useEffect(() => {
    console.log(channelTiers);    
  }, [channelTiers]);
 
  function newTier() {
    const tier = {
      id: uuidv4(),
      key: uuidv4(),
      name: '',
      amount: 0, 
      cost: 0
    };
    setChannelTiers([...channelTiers, tier]);
  }

  function validateTiers() {
    let tierV = false;
    for (let tier of channelTiers) {

    }
  }
  
  function stateChange(event, blah, blah2) {
    //const newList = event?.rows?.idRowsLookup?.values();
    //setChannelTiers(newList);
  }

  function editRow(event) {
    let newTierList = [];
    
    for (let tier of channelTiers) {
      if (tier.id === event.id) {
        newTierList.push({...event.row});
      } else {
        newTierList.push(tier);
      }
    }
    setChannelTiers(newTierList);
    //let modifiedTiers = [...newTierList];
    /*for (let tier of modifiedTiers) {
      tier.cost = ethers.utils.parseUnits(tier.cost.toString(), 'ether').toString();
    }*/
    //console.log(modifiedTiers);
    setTiers(newTierList);
  }

  return (
    <Box sx={{m:8}} >
      
      <Typography variant="h5" component="div" gutterBottom sx={{color: 'text.primary'}}>
        NFT Levels
        <strong style={{ marginLeft: 'auto', float: "right"}}>
          <IconButton aria-label="addRecord" onClick={newTier}>
            <AddIcon />
          </IconButton>
        </strong>
      </Typography>
      <div style={{ display: 'flex', height: '23.25rem', width: '100%'}}>
        <DataGrid
          rows={channelTiers}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          disableColumnMenu
          experimentalFeatures={{ newEditingApi: true }}
          editMode="row"
          //onStateChange={stateChange}
          onRowEditStop={editRow}
          sx={{
            '& .Mui-error': {
              bgcolor: (theme) =>
                `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
              color: (theme) => (theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f'),
            },
          }}
        />
      </div>
    </Box>
  );
}

export default TierTable;