
import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getChannelMetadata } from '../../service/worksManager';
import { cleanImageUrl, ipfsGateway } from '../../service/utility';

function ChannelFeedItem(input) {
  
  const [channelLink, setChannelLink] = useState("");
  const [channelMetadata, setChannelMetadata] = useState({});
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    const getMetadata = async (channelId) => {
      const metadataUri = await getChannelMetadata(channelId);
      if (metadataUri && metadataUri !== 'ipfs:///metadata.json' && metadataUri !== ipfsGateway+'metadata.json') {
        let convertedUri = metadataUri.replace('ipfs://', '');
        let metadataRequest = await fetch(ipfsGateway+convertedUri);
        let metadataResponse = await metadataRequest.json();
        metadataResponse["parse_image"] = await cleanImageUrl(metadataResponse.image);
        if(componentIsMounted.current) setChannelMetadata(metadataResponse);
      }
    } 
    if(componentIsMounted.current) setChannelLink('/channels/'+input?.channelId);
    if (input.channelMetadata) {
      if(componentIsMounted.current) setChannelMetadata(input.channelMetadata);
    } else if (input.channelId) {
      getMetadata(input.channelId);
    }
  }, [input]);

  const Image = styled('img')({
    "maxWidth": "85px", width: "-webkit-fill-available", borderRadius:'1rem'
  });

  return (
    <div style={{display: 'contents'}}>
      {channelMetadata !== null && 
        <Button component={Link} to={channelLink} sx={{textTransform:'none',backgroundColor: 'transparent !important'}}>
          <Box maxWidth="lg" sx={{mx: "auto", mb: 16, display: 'flex'}}>
            <Box sx={{m:8, display:'inline-block'}}>
              <Image src={channelMetadata?.parse_image} alt=''/>
              <Typography noWrap variant="h6" gutterBottom component="div" sx={{color: 'text.primary', my:0, fontSize:"80%"}}>{channelMetadata?.name}</Typography> 
              <Typography noWrap variant="caption" display="block" gutterBottom sx={{color: 'text.secondary'}}>{channelMetadata?.properties?.author}</Typography> 
            </Box>
          </Box>
        </Button>
      }
    </div>
    
  );
}

export default ChannelFeedItem;