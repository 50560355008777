import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ChannelList from '../../../components/channel-list';
import ChannelFeedList from '../../../components/channel-feed-list';
import PostList from './components/post-list';


function Subscription({store}) {
  return (
    <Box sx={{ flexGrow: 1, m: 16 }}>
      <Grid container spacing={2} sx={{m:4, pt:10}}>
        <Grid item xs={8}>            
          <Typography variant="h5" gutterBottom component="div" sx={{color: 'text.primary', my:0}}>Subscriptions</Typography> 
          <ChannelFeedList includeNew={true}></ChannelFeedList>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom component="div" sx={{color: 'text.primary', my:0}}>Recent Items</Typography> 
          <PostList store={store}></PostList>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </Box>
  );
}

export default Subscription;

/*
<div style={{ height: ' 100vh'}}>
  <ChannelList></ChannelList>
</div>
*/