import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { DataGrid } from '@mui/x-data-grid';
import { useContractCall } from "@usedapp/core";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import {BigNumber} from '@ethersproject/bignumber';
import { useEthers } from '@usedapp/core';
import { storeNFTMetadata, fetchMetadata } from '../../../../../service/utility.js';
import CreatePost from '../../../../../components/create-post';
import PostList from '../../../../../components/post-list';

import { 
  getMembershipUri, 
  createPostContract, 
  getChannelPostContract,  
  getChannelMetadata
} from '../../../../../service/worksManager';

function PostManager({setPost, store}) {
  const { channelId } = useParams();
  const { account, chainId } = useEthers();
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [postContract, setPostContract] = useState('');
  const [postIndex, setPostIndex] = useState(null);
  const [channelPosts, setChannelPosts] = useState([]);
  const [channelPostMetadata, setChannelPostMetadata] = useState([]);
  const [channelMetadata, setChannelMetadata] = useState([]);
  
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
 
  useEffect(() => {
    const _getChannelMetadata = async () => {
      try {
        if (channelId) {
          let channelUri = await getChannelMetadata(channelId);
          if (channelUri) {
            let metadataResponse = await fetchMetadata(BigNumber.from(channelId), channelUri);
            (metadataResponse && componentIsMounted.current) ? setChannelMetadata(metadataResponse) : console.log('error channel fetchMetadata');
          }
        }
      } catch(calloutError) {}
    } 
    _getChannelMetadata();
  }, [channelId, account, chainId])
  
  useEffect(() => {
    const getChannelPostMetadata = async () => {
      try {
        let metadataList = [];
        if (channelPosts.length > 0) {
          console.log(channelPosts);
          for (let membership of channelPosts) {
            let membershipMetadataUri = await getMembershipUri(membership);
            if (membershipMetadataUri) {
              let metadataResponse = await fetchMetadata(membership, membershipMetadataUri);
              (metadataResponse) ? metadataList.push(metadataResponse) : console.log('error fetchMetadata');
            }
          }
          console.log('channelPostMetadata', metadataList);
          if (componentIsMounted.current) {
            setChannelPostMetadata(metadataList);
          }          
        }
      } catch(calloutError) {}
    } 
    getChannelPostMetadata();
  },[channelPosts]);


  const getChannelPosts = async () => {
    if (channelId != null) {
      try {
        const postContractAddress = await getChannelPostContract(channelId);
        if (!BigNumber.from(postContractAddress).eq(0)) {
          console.log(postContractAddress);
          setPostContract(postContractAddress);
        }        
      } catch (e) {
        console.log(e);
      }
    }
    
  }; 

  const mintPostContract = async () => {
    try {
      const postContractAddress = await createPostContract(channelMetadata.name, channelId, store);
      getChannelPosts();
    } catch (e) {
      console.log(e);
    }
  }; 

  useEffect(() => {
    getChannelPosts();
  },[channelId, account, chainId]);

  useEffect(() => {
  },[channelMetadata]);

  
  function closeNewPostModal() {
    setCreateModalOpen(false);
  }

  function setPostData(_post) {
    setPost(_post, postContract);
  }

  return (
    <Box sx={{m:8}} >
      { 
        postContract !== '' && 
        (
          <div>
            <Box sx={{display: 'block'}}>
              <CreatePost contractAddress={postContract} store={store}></CreatePost>
              <Typography variant="h5" component="div" gutterBottom sx={{color: 'text.primary'}}>
                Posts
              </Typography>
            </Box>
            
            <Box sx={{display: 'block'}}>
              <PostList contractAddress={postContract} setPost={setPostData} store={store}></PostList>
            </Box>

            
          </div>
        )
      }

      {
        !postContract && 
        (
          
          <Box sx={{ display: 'flex'}}>
            <LoadingButton
              sx={{display: 'block', mx: "auto", width:150}}
              onClick={() => {
                mintPostContract();
              }}
              loading={submissionLoading}
              variant="outlined"
            >
              Mint Post Collection
            </LoadingButton>
          </Box>
        )
      }
    </Box>
  );
}

export default PostManager;