
import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BigNumber } from 'ethers';

import { getChannelMetadata } from '../../service/worksManager';
import { cleanImageUrl, ipfsGateway } from '../../service/utility';


function ChannelValue({channelId, value, type, color}) {
  const [metadata, setChannelMetadata] = useState({}); 
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    const getMetadata = async (channelId) => {
      const metadataUri = await getChannelMetadata(BigNumber.from(channelId));
      if (metadataUri && metadataUri !== 'ipfs:///metadata.json' && metadataUri !== ipfsGateway+'metadata.json') {
        let convertedUri = metadataUri.replace('ipfs://', '');
        let metadataRequest = await fetch(ipfsGateway+convertedUri);
        let metadataResponse = await metadataRequest.json();
        metadataResponse["parse_image"] = await cleanImageUrl(metadataResponse.image);
        if (componentIsMounted.current) setChannelMetadata(metadataResponse);
      }
    } 
    if (componentIsMounted.current && channelId) {
      getMetadata(channelId);
    }
  }, [channelId ]);



  return (
    <div style={{display: 'contents'}}>
      {metadata.name != null && 
        <Typography variant={type} sx={{color: color}}>{metadata[value]}</Typography>
      }
    </div>
  );
}

export default ChannelValue;

/*
<Box maxWidth="lg" sx={{mx: "auto", mb: 16, display: 'flex'}}>
  <Box sx={{m:8, display:'inline-block'}}>
    <Image src={channelMetadata?.parse_image} alt=''/>
    <Typography noWrap variant="h6" gutterBottom component="div" sx={{color: 'text.primary', my:0, fontSize:"80%"}}>{channelMetadata?.name}</Typography> 
    <Typography noWrap variant="caption" display="block" gutterBottom sx={{color: 'text.secondary'}}>{channelMetadata?.properties?.author}</Typography> 
  </Box>
</Box>
*/