import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Box, Grid} from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import FormHelperText from '@mui/material/FormHelperText';
import DialogContent from '@mui/material/DialogContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DialogTitle from '@mui/material/DialogTitle';
import { useEthers } from '@usedapp/core';
import { ethers } from 'ethers';

//import { basicSvg } from '../../../../static/constants';
import LoadingButton from '@mui/lab/LoadingButton';
import {mintApiChannel} from '../../service/worksManager';
import {storeNFTMetadata, convertURIToImageData, urlCheck} from '../../service/utility';
import { getPodcastList, buildChannelRow } from '../../service/buzzsprout';
import { postUserSetting, buildUserSetting } from '../../service/lambda';


// Custom Components
import UploadImage from '../upload-image';
import TextInput from '../text-input';
import ErrorToast from '../error-toast';
import ChannelCard from '../channel-card';
import TierTable from '../tier-table';

// Images
import buzzsproutLogo from '../../static/images/buzzsprout_square_transparent.png';
import buzzsproutLogoDark from '../../static/images/buzzsprout_square_transparent_dark.png';
import transistorLogo from '../../static/images/transistor-podcasting-logo-vertical.png';
import { useTheme } from '@mui/styles';

//mintChannel
//const svgString = basicSvg.svgPartOne + basicSvg.svgPartTwo + basicSvg.svgPartThree
const steps = ['Platform', 'Channel', 'Configuration'];

function CreateChannelApi({store}) {
  const { account, chainId } = useEthers();
  const theme = useTheme();
  let navigate = useNavigate();
  
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const [apiType, setApiType] = React.useState('');
  const [apiKey, setApiKey] = React.useState('');
  const handleApiKeyChange = async (event) => {
    console.log(account);
    if (event.textVal) {
      setApiKey(event.textVal);

      const podcastList = await getPodcastList(event.textVal);
      console.log(podcastList);
      let channelList = [];
      podcastList.forEach((v, i) =>  {
        const channel = buildChannelRow(v);
        channelList.push(channel);
      });
      handleMetadataChange(channelList);
    }
    
  };

  const [channelName, setChannelName] = useState('');
  const handleNameChange = (event) => {
    setChannelName(event.textVal);
  };

  
  const [tiers, setTiers] = useState([]);
  const [channelMetadataList, setChannelMetadataList] = useState([]);
  const handleMetadataChange = (mlist) => {
    setChannelMetadataList(mlist);
  };

  
  const [selectedChannel, setSelectedChannel] = useState({});

  function selectBuzzsprout() {
    setApiType('buzzsprout');
    handleNext();
  }

  function channelSelect(event) {
    setSelectedChannel(event);
    console.log(event);
    handleNext();
  }

  /**/
  const mintCreatorChannel = async function() {
    try {
      setSubmissionLoading(true);

      if (selectedChannel.name.length > 0) {
        const img = await convertURIToImageData(selectedChannel.image);
        //console.log(img);
        const metadata = await storeNFTMetadata(selectedChannel.name, selectedChannel.description, img, 'channel', {properties: selectedChannel.properties});
        //console.log('mint complete', metadata)
        const keywords = selectedChannel.name + ' ' + selectedChannel.properties.author + ' ' + selectedChannel.properties.language;   
        const channelId = await mintApiChannel(metadata.ipnft, selectedChannel.name, keywords, store);
        console.log(channelId);
        //Add postContractAddress to the payload
        const setting = buildUserSetting(channelId, apiKey, selectedChannel.properties.buzzsproutId, tiers, account);
        const result = await postUserSetting(setting);
        setSubmissionLoading(false);
        closeNewChannelModal(false);
        //syncEvent();
        navigate("/manage", { replace: true });
      } else {
        setSubmissionLoading(false);
      }
    } catch (err) {
      console.log(err);
      setSubmissionLoading(false);
      setErrorMessage(err.message);
    }
  }

  function newChannel() {
    setCreateModalOpen(true);
  }

  function closeNewChannelModal() {
    setChannelName('');
    setActiveStep(0);
    setApiKey('');
    setChannelMetadataList([]);
    setTiers([]);
    setSelectedChannel({});

    setCreateModalOpen(false);
  }

  // Stepper Functions
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const StepperFooter = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />

        <Button onClick={handleNext}>
          Next
        </Button>
      </Box>
    )
  }

  const listOfChannels = () => (
    <>
      { channelMetadataList.length > 0 && 
        channelMetadataList.map((channel) => {
          return (
            <Grid item key={channel.id}>
              <ChannelCard channelMetadata={channel} channelType="draft" channelSelection={channelSelect}></ChannelCard>
            </Grid>
          )        
        })
      }
    </>
  );

  const listOfTiers = () => (
    <TierTable store={store} setTiers={setTiers}></TierTable>
  )
      

  return (
    <>
      <Box sx={{my: 5, mx:'auto'}}>
        {/*<IconButton aria-label="addRecord" onClick={newChannel}>
          <AddIcon />
        </IconButton>*/}
        <Button variant="contained" onClick={newChannel} sx={{
          mx:'auto', textAlign: 'center', display: 'block', 
          borderRadius: "100rem", fontWeight:'600', 
          fontSize: '80%', textTransform: 'none'
        }}>Link Existing Channel</Button>
      </Box>
      <Dialog 
        open={createModalOpen} 
        onClose={closeNewChannelModal} 
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          New Api Channel 
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeNewChannelModal}
            sx={{float:'right'}}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{}}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Card sx={{ }} className="transparent-background">
              {activeStep === 0 && 
                <Box sx={{alignItems: 'flex-end'}}>
                  <Box maxWidth="max" sx={{ flexDirection: 'row', p: 8}}> 
                    <Button variant="bare" onClick={selectBuzzsprout} sx={{mx:'auto', display: 'block'}}> 
                    {theme.palette.mode === 'dark' && 
                      <img src={buzzsproutLogoDark} style={{width:'10vw'}}/>
                    }
                    {theme.palette.mode === 'light' && 
                      <img src={buzzsproutLogo} style={{width:'10vw'}}/>
                    }
                      
                    </Button>
                  </Box>
                  <StepperFooter></StepperFooter>
                </Box>
              }
              {activeStep === 1 && 
                <div>
                  <TextInput onTextChange={handleApiKeyChange} textType="h5" inputName="apiKey" inputType="text" defaultText="Enter Api Key" sx={{py: 8}}></TextInput>
                  <Grid container spacing={2} wrap={'nowrap'} sx={{p: 10}}>
                    {listOfChannels()}
                  </Grid>
                  <StepperFooter></StepperFooter>
                </div>
              }

              {activeStep === 2 && 
                <Box sx={{alignItems: 'flex-end'}}>
                  <Box maxWidth="max" sx={{ flexDirection: 'row', p: 8}}> 
                    <Box sx={{ justifyContent: 'center'}}>
                      {listOfTiers()}
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <LoadingButton
                      component="span"
                      loading={submissionLoading}
                      variant="contained"
                      onClick={mintCreatorChannel}
                    >
                      Create Channel
                    </LoadingButton>
                  </Box>
                </Box>
              }
          </Card>
        </DialogContent>
      </Dialog>
      
      <ErrorToast errorMessage={errorMessage}></ErrorToast>
      
    </>
  );
}

export default CreateChannelApi;


