import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChannelFeedItem from '../channel-feed-item';
import AddIcon from '@mui/icons-material/Add';
import { useEthers } from '@usedapp/core';

import { 
  getCurrentChannelIndex, 
  getChannelMetadata,
  getMembership 
} from '../../service/worksManager.js';
import { fetchMetadata } from "../../service/utility";
import { BigNumber } from 'ethers';

function ChannelFeedList(inputs) {
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  const { account, chainId } = useEthers();
  const [channelMetadata, setChannelMetadata] = useState([]);

  useEffect(() => {       
    let asynFunction = async () => {
      try {
        let currentIndex = await getCurrentChannelIndex();
        let channelList = [];
        if (currentIndex) {
          for (let i = 0;i <= currentIndex.toNumber();i++) {
            const channel = i === 0 ? BigNumber.from(0) : currentIndex.add(i);
            let channelMember = await getMembership(channel);
            if (channelMember.eq(1) || inputs.getAll) {
              let metadataUri = await getChannelMetadata(channel);
              if (metadataUri) {
                let metadataResponse = await fetchMetadata(channel, metadataUri);
                //updateChannelMap(channel, channelMedataResponse);
                if (metadataResponse.key !== undefined){
                  channelList.push(metadataResponse);
                }                
              }
            }
            
          }
          if (componentIsMounted.current) setChannelMetadata(channelList);
        }
      } catch (calloutError) {}
    }
    
    asynFunction();
    //return () => { _setIsMounted(false)};  
  },[account, chainId, inputs.getAll]);

  const listOfChannels = () => (
    <div style={{display: 'contents'}}>
      { 
        channelMetadata.map((channel) => {
          return (
            <div key={channel.key} style={{display: 'contents'}}>
              <ChannelFeedItem channelMetadata={channel} channelId={channel.id} store={inputs.store}></ChannelFeedItem>  
            </div>
          );
        })
      }

      {inputs.includeNew && 
        <Button component={Link} to="/channels" sx={{textTransform:'none',backgroundColor: 'transparent !important'}}>
          <Box maxWidth="lg" sx={{mx: "auto", mb: 16, display: 'flex'}}>
            <Box sx={{m:8, display:'inline-block'}}>
              <AddIcon sx={{
                color: 'text.disabled',
                fontSize:'1.5rem', 
                border: '1px solid', 
                borderColor: 'text.disabled',
                borderRadius: '1rem',
                p: 16, 
              }}/> 
              <Typography noWrap variant="h6" gutterBottom component="div" sx={{color: 'text.primary', my:0, fontSize:"80%"}}>&nbsp;</Typography> 
              <Typography noWrap variant="caption" display="block" gutterBottom sx={{color: 'text.secondary'}}>&nbsp;</Typography> 
            </Box>
          </Box>
        </Button>
      }
    </div>
  );
  return (
    <div>
      { channelMetadata.length > 0 && 
        <>
          {listOfChannels()
          }
        </>
      }
    </div>
    
  );
}

export default ChannelFeedList;

/*
<IconButton
  size="large"
  aria-label="add"            
  component={Link} 
  to="/channels"
  sx={{
    fontSize:'5rem', 
    border: '1px solid', 
    borderColor: 'text.disabled',
    borderRadius: '1rem',
    p: 16,
    verticalAlign: 'top'
  }}
  >
  <AddIcon />
</IconButton>
*/