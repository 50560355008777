import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEthers } from '@usedapp/core';

import { getCurrentChannelIndex, getChannelMetadata } from '../../service/worksManager.js';
import { fetchMetadata } from "../../service/utility";
import { ThemeContext } from '@emotion/react';
import { useTheme } from '@mui/material/styles';

function ChannelList() {
  const theme = useTheme();
  const columns = [
    {
      field: 'name',
      headerName: 'Channel Name',
      flex:2,
      editable: false,
      sortable: true
    },
    {
      field: 'description',
      headerName: 'Channel Description',
      flex:3,
      editable: false,
      sortable: false
    },
    {
      field: 'parse_image',
      headerName: 'Channel Image',
      flex:2,
      editable: false,
      sortable: false,
      renderCell: (params)=>{
        return (
            <img src={params.row.parse_image} alt='' style={{width: "-webkit-fill-available"}} />
        )
      }
    },
    {
      field: 'accessButton',
      renderHeader: () => (
        <IconButton color="primary" aria-label="refresh list" onClick={refreshList} sx={{mx:"auto", color: "text.primary"}}>
          <RefreshIcon />
        </IconButton>
      ),
      flex:1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        
        let channelLink = '/channels/'+params.row.id;
        if(theme.palette.mode === 'dark') {
          return <Button component={Link} to={channelLink} variant="outlined" color="primary" sx={{mx:"auto"}}>Access</Button>;
        } else if (theme.palette.mode === 'light') {
          return <Button component={Link} to={channelLink} variant="contained" color="primary" sx={{mx:"auto", boxShadow: 'none'}}>Access</Button>;
        }
      }
    }
  ];  
  const { account, chainId } = useEthers();
  const [channelMetadata, setChannelMetadata] = useState([]);  
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    //setChannelMetadata([]);
    //setChannelMap({});
    const getChannelList = async () => {
      try {
        let currentIndex = await getCurrentChannelIndex();
        console.log(currentIndex);
        let channelList = [];
        if (currentIndex) {
          for (let i = 0;i <= currentIndex.toNumber();i++) {
            const channel = currentIndex.sub(i);
            let channelMetadataUri = await getChannelMetadata(channel);
            if (channelMetadataUri && channelMetadataUri !== 'ipfs:///metadata.json') {
              let channelMetadataResponse = await fetchMetadata(channel, channelMetadataUri);
              //updateChannelMap(channel, channelMedataResponse);
              channelList.push(channelMetadataResponse);
            }
          }
          if (componentIsMounted.current) setChannelMetadata(channelList);
        }
      } catch (calloutError) {
      }
    } 
    getChannelList();
  },[account, chainId]);
  
  const refreshList = async () => {
    let currentIndex = await getCurrentChannelIndex();
    let channelList = [];
    if (currentIndex) {
      for (let i = 0;i <= currentIndex.toNumber();i++) {
        const channel = currentIndex.add(i);
        let channelMetadataUri = await getChannelMetadata(channel);
        if (channelMetadataUri && channelMetadataUri !== 'ipfs:///metadata.json') {
          let channelMetadataResponse = await fetchMetadata(channel, channelMetadataUri);
          //updateChannelMap(channel, channelMedataResponse);
          channelList.push(channelMetadataResponse);
        }
      }
      setChannelMetadata(channelList);
    }
  };

  return (
    <div style={{ height: ' 100vh'}}>
      {channelMetadata.length > 0 && 
        <div style={{ height: 400, width: '100%', paddingTop: '5vh'}}>
          <DataGrid
            rows={channelMetadata}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            disableColumnMenu
            sx={{mx:'auto', width: '80vw'}}
          />
        </div>

      }
      
    </div>
  );
}

export default ChannelList;