
import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import BalanceIcon from '@mui/icons-material/Balance';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import FormHelperText from '@mui/material/FormHelperText';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {BigNumber} from '@ethersproject/bignumber';
import { ethers } from 'ethers';
import matic_logo from '../../static/images/matic.png';
import { withdrawChannelBalance, getChannelBalance, getPostBalance, getChannelPostContract, withdrawPostBalance } from '../../service/worksManager';

import ErrorToast from '../error-toast';


function ChannelBalance(input) {
  
  const [isOwner, setIsOwner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [channelBalance, setChannelBalance] = useState(0);
  const [postBalance, setPostBalance] = useState(0);
  const [postContract, setPostContract] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  useEffect(() => {
    const runAsync = async () => {
      try {
        console.log('getChannelPostContract');
        const _postContract = await getChannelPostContract(input.channelId);
        if(componentIsMounted.current) setPostContract(_postContract);
        const _postBalance = await getPostBalance(_postContract);
        
        if(componentIsMounted.current) setPostBalance(ethers.utils.formatEther(_postBalance));
      } catch (calloutError) {
        console.log(calloutError);
      }
    } 
    runAsync();
  }, [input.channelId]);

  useEffect(() => {
    const runAsync = async () => {
      try {
        if (input.channelId) {          
          const _channelBalance = await getChannelBalance(input.channelId);        
          if(componentIsMounted.current) setChannelBalance(ethers.utils.formatEther(_channelBalance));
        }
        
      } catch (calloutError) {
        console.log(calloutError);
      }
    } 
    runAsync();
  }, [input.channelId]);

  const withdrawChannel = async () => {
    withdrawChannelBalance(input.channelId).then((result) => {
      console.log(result);
    });
  };

  const widthdrawPost = async () => {
    withdrawPostBalance(postContract).then((result) => {
      console.log(result);
    });
  };

  const openModal = () => {
    setIsOpen(true);
  };
  
  const closeModal = () => {
    setIsOpen(false);
  }
  const Image = styled('img')({
    "maxHeight": "150px", width: "-webkit-fill-available", borderRadius:'1rem'
  });
  return (
    <>
      <Chip icon={<BalanceIcon />} target="_blank" label="Check Balance" variant="filled" onClick={openModal} clickable sx={{ml:4,mt:2,mb:4,fontSize:12}} />
      <Dialog open={isOpen} onClose={closeModal} fullWidth={true} maxWidth="md">
        <DialogTitle>
          Channel Balance
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeModal}
            sx={{float:'right'}}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{display:'inline-block', width: '50%'}}>
            <Card variant="outlined">
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Memberships Balance
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{mb:8}}>
                  {channelBalance} <img src={matic_logo} alt="MATIC" className="icon-image"/>
                </Typography>
                <Button variant='outlined' onClick={withdrawChannel} disabled={parseFloat(channelBalance) === 0}>Withdraw</Button>
              </CardContent>
            </Card>
          </Box>
          <Box  sx={{display:'inline-block', width: '50%'}}>
            <Card variant="outlined">
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Posts Balance
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{mb:8}}>
                  {postBalance} <img src={matic_logo} alt="MATIC" className="icon-image"/>
                </Typography>
                <Button variant='outlined' onClick={widthdrawPost} disabled={parseFloat(postBalance) === 0.0}>Withdraw</Button>
              </CardContent>
            </Card>  
          </Box>
        </DialogContent>
      </Dialog>
      
      <ErrorToast errorMessage={errorMessage}></ErrorToast>
      
    </>
    
  );
}

export default ChannelBalance;