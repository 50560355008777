import React, {useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgressWithLabel from '../circular-progress-with-label';
import {BigNumber} from '@ethersproject/bignumber';
import { useEthers, useBlockNumber } from '@usedapp/core';
import { ethers } from 'ethers';
import { getTokenBalance } from '../../service/worksManager';
import logo from '../../static/images/logo.png';
 
function TransactionHandler({store}) {
  const blocknumber = useBlockNumber();  
  const { account } = useEthers();
  const [currentCount, setCurrentCount] = React.useState(0);
  const [balance, setTokenBalance] = React.useState(BigNumber.from(0));
  const [transactionList, setTransactionList] = React.useState([]);
  const theme = useTheme();
  useEffect(() => {
    store.subscribe(function(key, value){
      if (key === 'transactions' && value.length > 0) {
        const lastItem = value[value.length-1];
        if (!transactionList.includes(lastItem.hash)) {
          setTransactionList([...transactionList, lastItem]);
          awaitTransaction(lastItem);
        }
      }
    });

    
    
    //getBalance();
  });
  useEffect(() => {
    getBalance();
  }, [blocknumber, account])

  const getBalance = async () => {
    const _balance = await getTokenBalance();
    setTokenBalance(_balance);
  };

  const awaitTransaction = async (transaction) => {
    setCurrentCount(currentCount+1);
    await transaction.wait();
    setCurrentCount(currentCount-1);   
    //await getBalance();
  };

  return (
    <>
      { 
        balance.div(ethers.constants.WeiPerEther).toString()
      }
      <img src={logo} alt="RFM" className="icon-image transaction-icon"/>
      { 
        currentCount > 0 && 
        <CircularProgressWithLabel label={currentCount}></CircularProgressWithLabel>
      }
    </>
  );
}

export default TransactionHandler;