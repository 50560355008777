import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
//import { useEtherBalance, useEthers } from '@usedapp/core';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import BookmarksIcon from '@mui/icons-material/BookmarksOutlined';
import Divider from '@mui/material/Divider';
import SubscriptionsIcon from '@mui/icons-material/SubscriptionsOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import StorageIcon from '@mui/icons-material/Storage';
import GavelIcon from '@mui/icons-material/Gavel';
import { useTheme } from '@mui/material/styles';
import CreateChannel from '../create-channel';
import CreateChannelApi from '../create-channel-api';
import SocialIcons from '../social-icons';

import { ReactComponent as LogoDark } from '../../static/images/logo-long-white.svg';
import { ReactComponent as LogoLight } from '../../static/images/logo-long.svg';
import logoDark from '../../static/images/logo-long-white.svg';
import logoLight from '../../static/images/logo-long.svg';
import { useEthers } from '@usedapp/core';

function MenuLinks ({store}) {
  const theme = useTheme();
  useEffect(() => {
  }, []);
  const { account, deactivate } = useEthers();
  const [state, setState] = React.useState({
    top: false,
    left: true,
    bottom: false,
    right: false,
  });

  const toggleMenu = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  let artistLinkList = [
    /*{
      name: 'Dashboard',
      link: '/dashboard'
    },
    {
      name: 'Subscriptions',
      link: '/channels/subscription'
    },
    {
      name: 'Channels',
      link: '/channels'
    },
    {
      name: 'Queue',
      link: '/queue'
    }*/
  ];

  const list = (anchor) => (
    <Box
      sx={{ width: 250, mt:6, bgcolor: 'transparent' }}
      role="presentation"
    >
      <Box sx={{ mx: 'auto' }}>
        {theme.palette.mode === 'dark' && 
          //<img src={logoDark} className="side-image" alt="menuLogo"/>
          <LogoDark className="side-image" />
        }
        {theme.palette.mode === 'light' && 
          //<img src={logoLight} className="side-image" alt="menuLogo"/>
          <LogoLight className='side-image' />
        }
        
      </Box>
      <List sx={{ml:8, mt:10}}>
        {artistLinkList && 
          (artistLinkList.map((linkVal) => (
            <ListItem button component={Link} to={linkVal.link} key={linkVal.name}>   
              <ListItemIcon sx={{mr:-8}}>
                {linkVal.name === 'Dashboard' && 
                 <SpeedIcon />
                }
                {linkVal.name === 'Channels' && 
                 <StorageIcon />
                }
                {linkVal.name === 'Subscriptions' && 
                 <BookmarksIcon />
                }
                {linkVal.name === 'Messages' && 
                 <ChatBubbleOutlineIcon />
                }
                {linkVal.name === 'Governance' && 
                  <GavelIcon />
                }
                {linkVal.name === 'Queue' && 
                  <SubscriptionsIcon />
                }
              </ListItemIcon>
              <ListItemText primary={linkVal.name} sx={{}}/>
            </ListItem>
          )))
        }
        <a href="https://www.tally.xyz/governance/eip155:80001:0x7B679f710cB210129074155e8989d6E667aA8FeB" target="_blank" rel="noreferrer"
          style={{
            textDecoration: 'none'
          }}
        >
          <ListItem button key="governance_link" sx={{pt:10}}>
            <ListItemIcon sx={{mr:-8}}>
              <GavelIcon />
            </ListItemIcon>
            <ListItemText primary="Governance" sx={{color:theme.palette.text.primary}}/>
          </ListItem>
        </a>
        
        <ListItem sx={{mt:10}}>
          <CreateChannelApi store={store}></CreateChannelApi>
        </ListItem>
        
        <ListItem sx={{mt:0}}>
          <CreateChannel store={store}></CreateChannel>
        </ListItem>

        <Divider  sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            my: 20
          },
          mr:8, 
          borderWidth: 2, 
          backgroundColor: theme.palette.grey[200],      
          borderColor: theme.palette.grey[200]
        })}/>

        <ListItem button component={Link} to="/manage" key="manage-link" sx={{fontSize: '.5rem'}}>   
          <ListItemText primary="Settings" sx={{fontSize: '.5rem'}} className="secondary-links"/>
        </ListItem>
        
        <ListItem button component="a" target="_blank" href="https://www.reception.fm" key="help-link">   
          <ListItemText primary="Help" className="secondary-links"/>
        </ListItem>

        <ListItem>
          <SocialIcons 
            twitterUrl="https://www.twitter.com"
            emailUrl="mailto:admin@reception.fm"
            githubUrl="https://www.github.com"
            websiteUrl="https://www.google.com"
          ></SocialIcons>
        </ListItem>
      </List>
      
      <Box sx={{position: 'absolute', bottom:"1vh", width:"100%"}}>
        <Button onClick={() => deactivate()} style={{marginLeft:'auto', marginRight:'auto', display:'block', fontWeight: 600, fontSize: '80%', borderRadius: '100rem'}} variant="contained">Disconnect</Button>
      </Box>
    </Box>
  );

  return (
    <div style={{display:'inline-block'}}>
      {/*<MenuIcon onClick={toggleMenu('left', true)} sx={{verticalAlign: 'middle'}}></MenuIcon>*/}
      {account && 
        <Drawer
          anchor={'left'}
          open={state['left']}
          variant="persistent"
          onClose={toggleMenu('left', false)}
          sx={{border: 'none'}}
          PaperProps={{
            elevation: 4,
            sx: {border: 'none', boxShadow: 'none'}
          }}
        >
          {list('left')}
        </Drawer>
      }

      {!account && 
        <Drawer
          anchor={'left'}
          open={state['left']}
          variant="persistent"
          onClose={toggleMenu('left', false)}
        >
          <Box
            sx={{ width: 250, height: '100vh' }}
            role="presentation"
          ></Box>
        </Drawer>
      }

      
      
    </div>
  )
}
export default MenuLinks;