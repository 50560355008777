
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEthers } from '@usedapp/core';

import { getChannelMetadata, getChannelPostContract} from '../../../service/worksManager';
import { cleanImageUrl, ipfsGateway } from '../../../service/utility';
import PostFeedList from "../../../components/post-feed-list";
//import PostList from "../../../components/post-list";
import ChannelMainDetail from '../../../components/channel-main-detail';

function ChannelDetail({store}) {
  const { channelId } = useParams();
  const { account, chainId } = useEthers();

  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [post, setPost] = useState({});
  const [postContract, setPostContract] = useState('');
  const [postStatus, setPostStatus] = useState("");
  const [channelMetadata, setChannelMetadata] = useState({name: '', description: '', image: ''});
  const componentIsMounted = useRef(true)
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    //setChannelMetadata([]);
    //setChannelMap({});
    const getCMetadata = async () => {
      try {
        const channelUri = await getChannelMetadata(channelId);
        if (channelUri) {
          let convertedUri = channelUri.replace('ipfs://', '');
          let metadataRequest = await fetch(ipfsGateway+convertedUri);
          let metadataResponse = await metadataRequest.json();
          metadataResponse["parse_image"] = await cleanImageUrl(metadataResponse.image);
          if (componentIsMounted.current) {
            setChannelMetadata(metadataResponse);
          }        
        }
      } catch (calloutError) {}
    } 
    getCMetadata();
  },[channelId, account, chainId]);
  
  function clearPost() {
    setPostStatus('');
    setPost({});
  }
  
  function setPostValue(_post) {
    if (post.id === _post.id) {
      //setPostStatus('selected');
    } else if (_post.id != null) {
      setPostStatus('selected');
      setPost(_post);
    }
  }


  useEffect(() => {
    //setChannelMetadata([]);
    //setChannelMap({});
    const getChannelPosts = async () => {
      try {
        const postContractAddress = await getChannelPostContract(channelId);
        if (componentIsMounted.current) {
          setPostContract(postContractAddress);
        }
      } catch (e) {
        console.log(e);
      }
    }; 
    getChannelPosts();
  },[channelId, account, chainId]);

  const CustomTitle = styled(TextField)({
    '& input': {
      fontSize : "2rem",
      color:"white",
      WebkitTextFillColor: "white !important"
    },
  });

  const CustomDescription = styled(TextField)({
    '& textarea': {
      color:"white",
      WebkitTextFillColor: "white !important"
    },
  });

  const CustomBox = styled(Box)(({ theme }) => (({
    //backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#00000',
    marginLeft: "5rem",
    marginRight: "5rem",
    display:"block"
  })));

  return (
    <Box maxWidth="max" sx={{pt: 4, my: 8}}>
      <CustomBox>
        <Grid container spacing={2} sx={{mb: 16}}>
          <Grid item xs={4} >
            <ChannelMainDetail channelMetadata={channelMetadata} channelId={channelId} store={store}></ChannelMainDetail>
          </Grid>
          <Grid item xs={8} >
            <Typography variant="h6" gutterBottom component="div" sx={{color: 'text.primary', mt:20, mb:10}}>Available episodes</Typography>
            <PostFeedList contractAddress={postContract} setPost={setPostValue} store={store} channelId={channelId}></PostFeedList>
          </Grid>
          {/*<Grid item xs={12} sx={{m: 8}}>
            <MembershipDetail channelId={channelId}></MembershipDetail>
          </Grid>*/}
        </Grid>
      </CustomBox>      
    </Box>
    
  )
}

export default ChannelDetail;
