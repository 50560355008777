//import env from "react-dotenv";
import './App.css';
import * as React from 'react';
import store from './service/queueState';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './pages/home';
import Account from './pages/account';
import Header from './components/header/header';
import Footer from './components/footer';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

import Dashboard from './pages/dashboard';
import ChannelCardList from './components/channel-card-list';
import MessageView from './pages/messages';
import QueueFeedList from './components/queue-feed-list';

// Management View
import ArtistManager from './pages/channelManager/components/artistManager';
import ChannelDetail from './pages/channelManager/components/channelDetail';

// Fan View
import Subscription from './pages/channelViewer/subcription';
import FanChannelDetail from './pages/channelViewer/channelDetail';
import PostView from './pages/channelViewer/postView';
import ArtistDetail from './pages/channelViewer/artistDetail';


const MainBox = styled(Box)(({ theme }) => ({
  bgcolor: 'text.disabled',
  display: 'block',
  height: ' 100%',
  minHeight: 'calc(100vh - 4rem)',
  overflowX: 'hidden',
  backgroundColor: theme.palette.mode === 'dark' ? '#282c34' : '#00000',
  marginTop: '4rem',
  [theme.breakpoints.up('md')]: {
    marginLeft: '250px'
  }
}));


function App() {
  console.log('app render');
  React.useEffect(() => {
    document.title = "Reception.FM";
  }, []);

  const themeVal = React.useRef('dark');
  const [openDrawer, setOpenDrawer] = React.useState('');
  const [mdTheme, setMdTheme] = React.useState(createTheme({
    spacing: 2,
    palette: {
      mode: 'dark',
      primary: {
        main: '#ffd10b'
      }
    },
    typography: {
      fontFamily: [
        'Montserrat',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  }));
  const setTheme = (value) => {
    setMdTheme(createTheme({
      spacing: 2,
      palette: {
        mode: value,
        primary: {
          main: '#ffd10b'
        }
      },
      typography: {
        fontFamily: [
          'Montserrat',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
    }));
    localStorage.setItem("theme", value);
  }
  //
  return (
    <ThemeProvider theme={mdTheme}>
      <HashRouter>
        <Box sx={{backgroundColor: mdTheme.palette.mode === 'dark' ? '#282c34' : '#00000',}}>
          <Header updateTheme={setTheme} setOpenDrawer={setOpenDrawer} store={store}/>
          <MainBox>
            <Routes >
              <Route path='/' element={<Home store={store}/>} />
              <Route path='/dashboard' element={<Dashboard store={store}/>} />
              <Route exact path='account' element={<Account store={store}/>} />
              <Route exact path='queue' element={<QueueFeedList type="all" store={store}/>} />
              <Route exact path="manage" >
                <Route index  element={<ArtistManager store={store}/>} />
                <Route path=':channelId' element={<ChannelDetail store={store}/>} />
              </Route>
              <Route exact path="artist" >
                <Route path=':artistId' element={<ArtistDetail store={store}/>} />
              </Route>
              <Route exact path="channels" >
                <Route index element={<ChannelCardList type="all" store={store}/>} />
                <Route exact path="subscription"  element={<Subscription store={store}/>}/>
                <Route path=':channelId' >
                  <Route index element={<FanChannelDetail store={store}/>} />
                  <Route path=':postId' element={<PostView store={store}/>} />
                </Route>
              </Route>
              <Route exact path='messages' element={<MessageView store={store}/>} />
            </Routes>
          </MainBox>
          
          <Footer store={store} openDrawer={openDrawer} />
        </Box>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
