import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArtistList from './components/artist-list.js';
import ChannelFeedList from '../../components/channel-feed-list';
import ChannelCardList from '../../components/channel-card-list';
import { getProfileEvents } from '../../service/worksManager';
import { Typography } from '@mui/material';

function Dashboard () {
  return (
    <>
      <Box sx={{ flexGrow: 1, m: 16 }}>
        <Grid container spacing={2} sx={{m:4}}>
          <Grid item xs={8}>            
            <ArtistList></ArtistList>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={8}>
            {
              //<Typography variant="h5" gutterBottom component="div" sx={{color: 'text.primary', my:0}}>Channels</Typography> 
            }
            <ChannelCardList type="all"></ChannelCardList>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Box>
      
    </>
    
  )
}

export default Dashboard;