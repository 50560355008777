import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import {BigNumber} from '@ethersproject/bignumber';
import AddIcon from '@mui/icons-material/Add';

import { getPostIndex, getPostUri } from '../../service/worksManager';
import { fetchMetadata, buildQueueRow } from "../../service/utility";

function PostList({contractAddress, setPost, store}) {
  const [ queue, setState ] = store.useState('queue') || null;
  const columns = [
    {
      field: 'parse_image',
      headerName: 'Post Image',
      flex:2,
      editable: false,
      sortable: false,
      renderCell: (params)=>{
        if (!params.row) {
          return (
            <div></div>
          )
        }
        return (
          <img src={params.row.parse_image} alt='' style={{width: "-webkit-fill-available"}} />
        )
      }
    },
    {
      field: 'name',
      headerName: 'Post Title',
      flex:3,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        if (!params.row) {
          return (<CircularProgress />);
        }

        return (
          <span>{params.row.name}</span>
        )
      }
    },
    {
      field: 'accessPost',
      renderHeader: () => (
        <IconButton color="primary" aria-label="refresh list" onClick={refreshList} sx={{mx:"auto", color: "text.primary"}}>
          <RefreshIcon />
        </IconButton>
      ),
      flex:1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (!params.row) {
          return (
            <div></div>
          )
        }

        if (!params.row.parse_properties) {
          return <Button variant="outlined" color="primary" sx={{mx:"auto"}}>Mint</Button>
        }
        
        function addToQueue() {
          let queueRow = buildQueueRow(params.row);
          var compareArray = queue.filter(value => value.key === queueRow.key);
          var copyArray = JSON.parse(JSON.stringify(queue));
          if (compareArray.length === 0) {
            copyArray.push(queueRow);
            setState(copyArray);
          }
        } 
        //let channelLink = '/channels/'+channelId+'/'+params.row.id;
        //return <Button component={Link} to={channelLink} variant="outlined" color="primary" sx={{mx:"auto"}}>View</Button>
        return (
          <IconButton aria-label="addToQueue" onClick={addToQueue} variant="outlined" color="primary" sx={{mx:"auto"}}>
            <AddIcon />
          </IconButton>
        )
      }
    }
  ];
  const [postMetadata, setPostMetadata] = useState([]);
  const [postIndex, setPostIndex] = useState(BigNumber.from(0));
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  useEffect(() => {
    const getPostList = async () => {
      if (contractAddress !== '') {
        const pI = await getPostIndex(contractAddress);
        if (componentIsMounted.current) {
          setPostIndex(pI);
        }        
      }      
    } 
    getPostList();
  }, [contractAddress]);

  useEffect(() => {
    //setPostMetadata([]);
    //setPostMap({});
    const getPostList = async () => {
      try {
        let pIndex = postIndex.toNumber();
        //console.log(pIndex);
        let postList = [];
        for (let i = 0; i < pIndex; i++) {
          let indexNum = BigNumber.from(i);
          //console.log(indexNum);
          let postMetadataUri = await getPostUri(contractAddress, indexNum);
          //console.log(postMetadataUri);
          if (postMetadataUri && postMetadataUri.length > 0) {
            let postMetadataResponse = await fetchMetadata(indexNum, postMetadataUri);
            postList.push(postMetadataResponse);
          }
        }
        //console.log(postList[0].parse_image);
        if(componentIsMounted.current) {
          console.log(postList);
          setPostMetadata(postList);
        }
      } catch (e) {
        console.log(e);
      }     
    } 
    getPostList();
  },[postIndex, contractAddress]);

  const refreshList = async () => {
    setPostIndex(BigNumber.from(0));
    const pI = await getPostIndex(contractAddress);
    setPostIndex(pI);
  }

  return (
    <div className="" style={{ backgroundColor: 'text.disabled'}}>
      <div style={{ height: 400, width: '100%'}}>
        <DataGrid
          rows={postMetadata}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          disableColumnMenu
        />
      </div>
    </div>
  );
}

export default PostList;