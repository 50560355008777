import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import {BigNumber} from '@ethersproject/bignumber';
import {Chip} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {
  getMembershipList, 
  getMembership,
  getPostUri, 
  getPostTokenBalance, 
  postIsBuyable, 
  postIsMintable, 
  postGetCost,
  postMint,
  postMintPayable
} from '../../service/worksManager';
import { fetchMetadata} from '../../service/utility';
import logo from '../../static/images/logo.png';
import ErrorToast from '../error-toast';

function PostMint({postContract, postId, channelId, store}) {
  
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isOwner, setIsOwner] = useState(false);
  const [isAccessible, setIsAccessible] = useState(false);
  const [isMintable, setIsMintable] = useState(false);
  const [isBuyable, setIsBuyable] = useState(false);
  const [, setMembershipId] = React.useState('');
  const [cost, setCost] = useState(0);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);


  const [channelMetadata, setChannelMetadata] = useState('');
  const handleMetadataChange = (metadata) => {
    setChannelMetadata(metadata);
  };
  

  //Effect for querying main data
  useEffect(() => {
    //setChannelMetadata([]);
    //setChannelMap({});
    const runAsyncFunction = async () => {
      try {
        const postUri = await getPostUri(postContract, postId);
        let postMetadataResponse = await fetchMetadata(BigNumber.from(postId), postUri);
        if (componentIsMounted.current) handleMetadataChange(postMetadataResponse);

        const postOwned = await getPostTokenBalance(postContract, postId);
        if (postOwned.eq(1) && componentIsMounted.current) {
          setIsOwner(true);
        }

        const _isMintable = await postIsMintable(postContract, postId);
        if (componentIsMounted.current) setIsMintable(_isMintable);
        const _isBuyable = await postIsBuyable(postContract, postId);
        if (componentIsMounted.current) setIsBuyable(_isBuyable);
        const _cost = await postGetCost(postContract, postId);
        if (componentIsMounted.current) setCost(_cost);

      } catch (e) {
        console.log(e);
      }
    }; 
    
    if (postContract && postId) {
      runAsyncFunction();
    }    
  }, [postContract, postId, cost, isBuyable, isMintable]);

  //Effect for membership info
  useEffect(() => {
    const runAsyncFunction = async () => {
      try {
        const membershipLists = await getMembershipList(channelId);
        for (let membership of membershipLists) {
          let membershipResult = await getMembership(membership);
          if(membershipResult.eq(1)) {
            if (componentIsMounted.current) setMembershipId(membership);
            break;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }; 

    if (channelMetadata.parse_properties && componentIsMounted.current) {
      setIsAccessible(true);
    }
    if (channelId) runAsyncFunction();
    
  }, [channelMetadata, channelId]);

  /**/
  const mintPost = async function() {
    try {
      setSubmissionLoading(true);

      if (isAccessible) {
        //handleMetadataChange(metadata.ipnft);      
        await postMint(postContract, BigNumber.from(postId), store);
        //postMint
        setSubmissionLoading(false);

      } else {
        await postMintPayable(postContract, BigNumber.from(postId), cost, store);
        //postMint
        setSubmissionLoading(false);
      }
    } catch (err) {
      console.log(err);
      setSubmissionLoading(false);
      setErrorMessage(err.message);
    }
    
    
  }

  return (
    <Box sx={{ml: "auto", width:250, display: 'flex'}}>
      {!isOwner && isMintable && 
        <Chip icon={<AccountBalanceIcon />} label="&nbsp;&nbsp;Mint" disabled={submissionLoading} onClick={mintPost} variant="outlined" clickable sx={{mt:2,mb:4,fontSize:12}} />
      }
      {isOwner && 
        <img src={logo} style={{"maxWidth" : "2rem"}} alt="OwnedNFT"/>
      }
      <ErrorToast errorMessage={errorMessage}></ErrorToast>
    </Box>
  );
}

export default PostMint;


/*          
<LoadingButton
  sx={{display: 'flex', width:100}}
  onClick={() => {
    mintPost();
  }}
  loading={submissionLoading}
  variant="outlined"
>
  Mint
</LoadingButton>
*/