
import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useEthers } from '@usedapp/core';

import { queryUserSetting } from '../../../../service/lambda';
import { getChannelMetadata } from '../../../../service/worksManager';
import { cleanImageUrl, ipfsGateway } from '../../../../service/utility';
import ChannelFeedItem from '../../../../components/channel-feed-item';
import MembershipList from "./components/membershipList.js";
import PostManager from "./components/postManager.js";
import MessageList from "./components/messageList.js";
import ChannelFeedDetail from '../../../../components/channel-feed-detail';

function ChannelDetail({store}) {
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  const { channelId } = useParams();
  const { account, chainId } = useEthers();

  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [post, setPost] = useState({});
  const [postStatus, setPostStatus] = useState("");
  const [postContract, setPostContract] = useState("");
  const [channelMetadata, setChannelMetadata] = useState({});

  useEffect(() => {
    //setChannelMetadata([]);
    //setChannelMap({});
    const getCMetadata = async () => {
      try {
        const channelUri = await getChannelMetadata(channelId);
        if (channelUri && account && chainId) {
          let convertedUri = channelUri.replace('ipfs://', '');
          let metadataRequest = await fetch(ipfsGateway+convertedUri);
          let metadataResponse = await metadataRequest.json();
          metadataResponse["parse_image"] = await cleanImageUrl(metadataResponse.image);
          if (componentIsMounted.current) {setChannelMetadata(metadataResponse);}
        }
      } catch (calloutError) {}
    } 
    getCMetadata();
  },[channelId, account, chainId]);

  useEffect(() => {
    if (post.id != null && componentIsMounted.current) {
      setPostStatus('selected');
    } else if (componentIsMounted.current) {
      setPostStatus('');
    }
  }, [post]);

  useEffect(() => {
    //console.log(channelMetadata);
    if (channelMetadata?.properties?.type === 'api' && componentIsMounted.current) {
      queryUserSetting(channelId, account+chainId);
    }
  }, [channelMetadata]);

  function clearPost() {
    setPostStatus('');
    setPost({});
  }

  function setPostValue(_post, _postContract) {
    if (post.id === _post.id) {
      //setPostStatus('selected');
    } else if (_post.id != null) {
      setPost(_post);
      setPostContract(_postContract);
    }
  }


  const Item = ({val, title, variant}) => {
    return (
      <Stack spacing={0}  sx={{ display:'inline-block'}}>
        <Typography gutterBottom variant={variant} color="text.primary" component="div">
          {val}
        </Typography>
        <hr />
        <Typography color="text.primary" variant="subtitle2">
          {title}
        </Typography>
      </Stack>
    )
  }

  const CustomTitle = styled(TextField)({
    '& input': {
      fontSize : "2rem",
      color:"white",
      WebkitTextFillColor: "white !important"
    },
  });

  const CustomDescription = styled(TextField)({
    '& textarea': {
      color:"white",
      WebkitTextFillColor: "white !important"
    },
  });

  return (
    <>
      { channelMetadata && 
        <Box maxWidth="max" sx={{pt: 4, my: 8}} >
          <ChannelFeedDetail channelMetadata={channelMetadata} store={store} ownerChannelId={channelId}></ChannelFeedDetail>

          <Box maxWidth="lg" sx={{ mb: 32}}>
            <Grid container spacing={2} sx={{mb: 16}}>
              { 
                //unlocking memberships for API
                /*channelMetadata?.properties?.type === 'api' && 
                <>
                  <Grid item xs={12} md={12} >
                    <PostManager setPost={setPostValue} store={store}></PostManager>
                  </Grid>
                </>*/
              }

              { /*(!channelMetadata?.properties?.type || channelMetadata?.properties?.type !== 'api') && */
                <>
                  <Grid item xs={12} md={5} sx={{mx: "auto", mb: 16}}>
                    <MembershipList store={store}></MembershipList>
                  </Grid>
                  <Grid item xs={12} md={7} >
                    <PostManager setPost={setPostValue} store={store}></PostManager>
                  </Grid>
                </>
              }
              
            </Grid>

          </Box>
          { 
            // Removing message list entirely
            /*(!channelMetadata?.properties?.type || channelMetadata?.properties?.type !== 'api') && 
            <Box maxWidth="lg" sx={{}}>
              <MessageList store={store}></MessageList>
            </Box>*/
          }
          
          
        </Box>

      }
    </>
  )
}

export default ChannelDetail;

/*


<Item val={channelMetadata.name} title="Title" variant="h4" style={{marginRight:"10px"}}></Item>

*/