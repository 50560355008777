import React, { useState } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuLinks from './menu';
import {AccountButton} from './accountButton';
import AvatarButton from './avatarButton.js';
import SearchInput from '../search-input';
import logoDark from '../../static/images/logo-long-white.svg';
import { ReactComponent as LogoDark } from '../../static/images/logo-long-white.svg';
import logoLight from '../../static/images/logo-long.svg';
import { ReactComponent as LogoLight } from '../../static/images/logo-long.svg';


function Header({updateTheme, setOpenDrawer, store}) {
  const {account} = useEthers();
  const [menuType, setMenuType] = useState('');
  const currentTheme = useTheme();
  let navigate = useNavigate();
  const location = useLocation();

  function menuHasChanged(menu) {
    let menuResponse = menu;
    if (menuResponse === 'artist' && !location.pathname.startsWith('/manage')) {
      //navigate("/");
    } else if (menuResponse === 'fan' && !location.pathname.startsWith('/channels')){
      //navigate("/");
    } else if (menuResponse === '' && location.pathname.startsWith('/manage')) {
      menuResponse = 'artist';
    } else if (menuResponse === '' && location.pathname.startsWith('/channels')) {
      menuResponse = 'fan';
    }

    setMenuType(menuResponse);
  }

  function toHome() {
    navigate("/", { replace: true });
  }

  return (
    <div style={{display: 'flex'}}>
      <MenuLinks sx={{ flexGrow: 1, mx: 6, display:'inline-block', zIndex: 2000 }} ui={menuType} store={store}/>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" color="text" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, boxShadow: 'none' }}>
          <Toolbar>           
            <Box sx={{ flexGrow: 1, mx: 6 }}>
              {account && 
                <AvatarButton></AvatarButton>
              }
              {
                /*<Button onClick={toHome} >
                  {currentTheme.palette.mode === 'dark' && 
                    //<img src={logoDark} alt="Reception.FM" className="header-image"/>
                    //<LogoDark className='header-image'/>
                    <></>
                  }
                  {currentTheme.palette.mode === 'light' && 
                    //<img src={logoLight} alt="Reception.FM" className="header-image"/>
                    ///LogoLight className='header-image'/>
                    <></>
                  }
                  
                </Button>*/
              }
              
              
            </Box>
            <Box sx={{ flexGrow: 6 }}>
              {
                <SearchInput></SearchInput>
              }
            </Box>
            {account ? (
              <Box sx={{ flexGrow: 1, textAlign: 'right'  }}>
                <AccountButton sx={{ mx: 6, display:'inline-block' }} menuChange={menuHasChanged} ui={menuType} updateTheme={updateTheme} setOpenDrawer={setOpenDrawer} store={store}></AccountButton>
              </Box>
            ) : (
              <AccountButton sx={{ mx: 6 }} menuChange={menuHasChanged} updateTheme={updateTheme} setOpenDrawer={setOpenDrawer} store={store}></AccountButton>
            )}          
          </Toolbar>
        </AppBar>
        
        {
          /*
          account && 
          <ChannelListSidebar></ChannelListSidebar>
          */
        }
      </Box>
    </div>
    
  );
}
// 
export default Header;