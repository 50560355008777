
import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';

import { getProfileUri } from '../../service/worksManager';
import { createReceptionSVG, cleanImageUrl, truncateString, ipfsGateway } from '../../service/utility';

function ArtistFeedItem(input) {
  const [placeholderImage, setPlaceholderImage] = useState("");
  const [artistLink, setArtistLink] = useState("");
  const [metadata, setMetadata] = useState({name: '', description: '', image: ''});
  const theme = useTheme();  
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    const getMetadata = async (account) => {
      const metadataUri = await getProfileUri(account);
      if (metadataUri && metadataUri !== 'ipfs:///metadata.json' && metadataUri !== ipfsGateway+'metadata.json') {
        let convertedUri = metadataUri.replace('ipfs://', '');
        let metadataRequest = await fetch(ipfsGateway+convertedUri);
        let metadataResponse = await metadataRequest.json();
        metadataResponse["parse_image"] = await cleanImageUrl(metadataResponse.image);
        if (componentIsMounted.current) setMetadata(metadataResponse);
      }
    } 
    
    if (componentIsMounted.current) setPlaceholderImage(URL.createObjectURL(createReceptionSVG('')));
    if (input.metadata && componentIsMounted.current) {
      setMetadata(input.metadata);
    } else if (input.id) {
      getMetadata(input.id);
      if (componentIsMounted.current) setArtistLink('/artist/'+input.id);
    }
  }, [input]);
  
  return (
    <>
      {metadata && 
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              component={Link} 
              // ToDo: add specific link lookup when Artist detail page is made
              to={artistLink}
            >
              <Avatar alt={metadata?.name} src={metadata?.parse_image} sx={{
                borderColor: theme.palette.grey[400],
                borderStyle: 'solid',
                borderWidth: 'thin'
              }} />
            </IconButton>
          </ListItemAvatar>
          <ListItemText
            primary={
              
              <Typography
                component="span"
                variant="h6"
                color="text.primary"
              >{metadata?.name}</Typography>
              
            }
            secondary={
              <Typography
                sx={{ display: 'block' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {metadata?.properties?.author}
                <span style={{fontSize : '80%', color:'text.primary', display: 'block'}}>{truncateString(metadata?.description,80)}</span>
              </Typography>              
            }
          />
          
        </ListItem>
      }

      {!metadata && 
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={placeholderImage} />
          </ListItemAvatar>
          <ListItemText
            primary="No artist profile"
          />
        </ListItem>
      }

    </>
    
  );
}

export default ArtistFeedItem;