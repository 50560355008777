
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { useEthers, shortenAddress, useLookupAddress } from '@usedapp/core';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { getProfileUri, setProfileUri } from '../../../service/worksManager';
import { cleanImageUrl, ipfsGateway } from '../../../service/utility';
import ArtistFeedDetail from '../../../components/artist-feed-detail';
import ChannelCardList from '../../../components/channel-card-list';

function ArtistDetail({store}) {
  const { artistId } = useParams();
  const { account, chainId, deactivate, activateBrowserWallet } = useEthers();
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [post, setPost] = useState({});
  const [postStatus, setPostStatus] = useState("");
  const [postContract, setPostContract] = useState("");
  const [artistMetadata, setArtistMetadata] = useState({name: '', description: '', image: ''});
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    //setChannelMetadata([]);
    //setChannelMap({});
    const getMetadata = async () => {
      try {
        let metadataUri;
        if (artistId) {
          metadataUri= await getProfileUri(artistId);
        } else {
          metadataUri= await getProfileUri(account);
        }
        
        if (metadataUri && metadataUri !== 'ipfs:///metadata.json' && metadataUri !== ipfsGateway+'metadata.json') {
          let convertedUri = metadataUri.replace('ipfs://', '');
          let metadataRequest = await fetch(ipfsGateway+convertedUri);
          let metadataResponse = await metadataRequest.json();
          metadataResponse["parse_image"] = await cleanImageUrl(metadataResponse.image);
          if (componentIsMounted.current) setArtistMetadata(metadataResponse);
        }
      } catch(e) {}
    } 
    getMetadata();
  },[account, chainId]);

  return (
    <Box maxWidth="max" sx={{pt: 4, my: 8}} >
      {account && artistMetadata.name !== '' &&
        <ArtistFeedDetail metadata={artistMetadata} store={store}></ArtistFeedDetail> 
      }
      {account && artistMetadata.name === '' &&
        <ArtistFeedDetail store={store}></ArtistFeedDetail> 
      }
      {account && artistId && 
        <ChannelCardList artistId={artistId} type="artist"></ChannelCardList> 
      }
    </Box>
  )
}

export default ArtistDetail;

/*


<Item val={channelMetadata.name} title="Title" variant="h4" style={{marginRight:"10px"}}></Item>

*/