
import { ethers } from 'ethers';
import worksManagerAbi from '../static/contracts/worksManagerAbi.json';
import postAbi from '../static/contracts/postContractAbi.json';
import tokenAbi from '../static/contracts/tokenAbi.json';
import { env } from '../static/constants';
import { fetchIPNS } from '../service/utility.js';
import { queryEvents } from '../service/lambda.js';
import {BigNumber} from '@ethersproject/bignumber';
const { ethereum } = window;
const CHANNEL_COST = ".02"; 

export const mintChannel = async function(channelMetadata, keywords, store) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.mintChannel(channelMetadata, keywords, options);
  console.log('nftTx',nftTx);
  let storeTxns = store.getState('transactions');
  const newTxnList = [...storeTxns.value, nftTx];
  store.onStoreUpdate('transactions', newTxnList);
  return 'success'; 
}

export const mintApiChannel = async function(channelMetadata, tokenName, keywords, store) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );

  const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  console.log(options);
  let nftTx = await nftContract.mintApiChannel(channelMetadata, tokenName, keywords, options);
  await nftTx.wait();
  let events = await nftContract.queryFilter('NewChannel', -1, "latest");
  let channelId;
  let latestBlock = 0;
  for (let e of events) {
    if (
      e.args[1].toLowerCase() === ethereum._state.accounts[0]
    ) {
      if (e.blockNumber >= latestBlock) {
        latestBlock = e.blockNumber;
        channelId = e.args[0];
      }
    }
  }
  return channelId;
}

export const getOwnerChannelIds = async function() {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.getOwnerChannelIds();
  return nftTx;
}

export const getArtistChannelIds = async function(artist) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.getArtistChannelIds(artist);
  return nftTx;
}

export const getChannelBalance = async function(channelId) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.getChannelBalance(channelId);
  return nftTx;
  
}

export const withdrawChannelBalance = async function(channelId) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.withdrawChannelBalance(channelId);
  return nftTx;
}

export const getChannelMetadata = async function(channelId) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.getChannelMetadata(channelId);
  return nftTx;
}

export const getChannelOwner = async function(channelId) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.getChannelOwner(channelId);
  return nftTx;
}


export const membershipTokenCreate = async function(channel, cost, computedUri, store) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let parsedCost = ethers.utils.parseEther(String(cost));
  let nftTx = await nftContract.membershipTokenCreate(channel, parsedCost, computedUri);
  let storeTxns = store.getState('transactions');
  const newTxnList = [...storeTxns.value, nftTx];
  store.onStoreUpdate('transactions', newTxnList);
  return 'success'; 
}

export const getMembershipList = async function(channelId) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.getMembershipList(channelId);
  return nftTx;
}

export const getMembershipUri = async function(membershipId) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.membershipUri(membershipId);
  return nftTx;
}

export const getMembership = async function(membershipId) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  let nftTx = await nftContract.getMembership(membershipId);
  return nftTx;
}

export const mintChannelMembership = async function(membership, cost, store) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  const options = {value: String(cost)}
  let nftTx = await nftContract.membershipMint(membership, options);

  let storeTxns = store.getState('transactions');
  const newTxnList = [...storeTxns.value, nftTx];
  store.onStoreUpdate('transactions', newTxnList);
  return 'success'; 
}

export const getChannelPostContract = async function(channelToken) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.getChannelPostContract(channelToken);
  return nftTx;
}

export const createPostContract = async function(tokenName, channel, store) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.createPostContract(tokenName, channel);
  await nftTx.wait();
  return 'success';
}

export const getPostIndex = async function(postAddress) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  let nftTx = await nftContract.getPostTokenIndex(postAddress);
  return nftTx;
}

export const getPostUri = async function(postAddress, postId) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  let nftTx = await nftContract.getPostUri(postAddress, postId);
  return nftTx;
}

export const getPostTokenBalance = async function(postAddress, postId) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  let nftTx = await nftContract.getPostTokenBalance(postAddress, postId);
  return nftTx;
}

export const postIsMintable = async function(postAddress, postId) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  let nftTx = await nftContract.postIsMintable(postAddress, postId);
  return nftTx;
}

export const postIsBuyable = async function(postAddress, postId) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  let nftTx = await nftContract.postIsBuyable(postAddress, postId);
  return nftTx;
}

export const postGetCost = async function(postAddress, postId) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  let nftTx = await nftContract.postGetCost(postAddress, postId);
  return nftTx;
}

export const postMint = async function(postAddress, postId, store) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  let nftTx = await nftContract.postMint(postAddress, postId);
  let storeTxns = store.getState('transactions');
  const newTxnList = [...storeTxns.value, nftTx];
  store.onStoreUpdate('transactions', newTxnList);
  return 'success'; 
}

export const postMintPayable = async function(postAddress, postId, cost, store) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId ===  Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  const options = {value: String(cost)}
  let nftTx = await nftContract.postMint(postAddress, postId, options);
  let storeTxns = store.getState('transactions');
  const newTxnList = [...storeTxns.value, nftTx];
  store.onStoreUpdate('transactions', newTxnList);
  return 'success'; 
}

export const createPostToken = async function(postAddress,channelId, cost, isBuyable, isPublic, airdrop, computedUri, paywallUri, mintable, levels, store) {
  //console.log(postAddress);
  const newPostAddress = ethers.utils.getAddress(postAddress);
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = ethers.utils.getAddress((chainId === Number(env.REACT_APP_CHAIN_ID)) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI);
  //console.log(worksManagerAddress);
  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  const ownerAddress = await signer.getAddress();
  const createPostData = {
    owner: ownerAddress,
    contractAddress: newPostAddress,
    cost,
    isBuyable,
    isPublic,
    airdrop,
    channelId,
    computedUri,
    paywallUri,
    mintable,
    levels,
    premine: false,
    premineAmount: 0,
  };
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  //console.log(createPostData, nftContract);
  let nftTx = await nftContract.createPostToken(createPostData);
  //nftContract.createPostToken(createPostData).then(nftTx => {
    console.log(nftTx);
    let storeTxns = store.getState('transactions');
    const newTxnList = [...storeTxns.value, nftTx];
    store.onStoreUpdate('transactions', newTxnList);
    return 'success'; 
  //})
  //console.log('nftTx',nftTx);
  
}


export const getPostBalance = async function(postAddress) {
  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    postAddress,
    postAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.getBalance();
  return nftTx;
  
}

export const withdrawPostBalance = async function(postAddress) {
  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    postAddress,
    postAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.withdrawBalance();
  return nftTx;
}

export const getCurrentChannelIndex = async function() {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId === Number( Number(env.REACT_APP_CHAIN_ID))) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;
  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.getCurrentChannelIndex();
  return nftTx;
}

export const setProfileUri = async function(computedUri, keywords, store) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId === Number( Number(env.REACT_APP_CHAIN_ID))) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;
  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.setProfileUri(computedUri, keywords);
  let storeTxns = store.getState('transactions');
  const newTxnList = [...storeTxns.value, nftTx];
  store.onStoreUpdate('transactions', newTxnList);
  return 'success'; 
}

export const getProfileUri = async function(to) {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId === Number( Number(env.REACT_APP_CHAIN_ID))) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;
  console.log(worksManagerAddress);
  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const nftContract = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  //const options = {value: ethers.utils.parseEther(CHANNEL_COST)}
  let nftTx = await nftContract.getProfileUri(to);
  return nftTx;
}

export const getProfileEvents = async function() {
  let events = await queryEvents('ProfileSet', '');
  return events;
}

export const getPostEvents = async function() {
  let events = await queryEvents('NewPost', '');
  return events;
}

export const getChannelEvents = async function() {
  let events = await queryEvents('NewChannel', '');
  return events;
}


export const getMessageEventsByChannel = async function(channelId) {
  let events = await queryEvents('NewPostMessage', channelId);
  return events;
}

export const getProfileEventsHistorical = async function() {
  let events = await queryEvents('ProfileSet', '');
  return events;
}

export const getNewPostEventsHistorical = async function() {
  let events = await queryEvents('NewPost', '');
  return events;
}

export const getNewMessageEventsHistorical = async function() {
  let events = await queryEvents('NewPostMessage', '');
  return events;
}

export const createPostMessage = async function(contractAddress, channelId, message, store) {

  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const worksManagerAddress = (chainId === Number( Number(env.REACT_APP_CHAIN_ID))) ? env.REACT_APP_WORKSMANAGER_ADDRESS : env.REACT_APP_WORKSMANAGER_ADDRESS_MUMBAI;
  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const worksManager = new ethers.Contract(
    worksManagerAddress,
    worksManagerAbi.abi,
    signer
  );
  let nftTx = await worksManager.createPostMessage(contractAddress, channelId, message);
  let storeTxns = store.getState('transactions');
  const newTxnList = [...storeTxns.value, nftTx];
  store.onStoreUpdate('transactions', newTxnList);
  return 'success'; 
}

export const getTokenBalance = async function() {
  const chainId = BigNumber.from(ethereum.chainId).toNumber();
  const tokenAddress = (chainId === Number( Number(env.REACT_APP_CHAIN_ID))) ? env.REACT_APP_TOKEN_ADDRESS : env.REACT_APP_TOKEN_ADDRESS_MUMBAI;
  
  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const token = new ethers.Contract(
    tokenAddress,
    tokenAbi.abi,
    signer
  );
  let tokenBalance = await token.callStatic.balanceOf(signer.getAddress());
  return tokenBalance; 
}
