import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import FormHelperText from '@mui/material/FormHelperText';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import DialogContent from '@mui/material/DialogContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DialogTitle from '@mui/material/DialogTitle';

//import { basicSvg } from '../../../../static/constants';
import LoadingButton from '@mui/lab/LoadingButton';
import {setProfileUri} from '../../service/worksManager';
import {storeNFTMetadata, urlCheck} from '../../service/utility';
import { syncEvent } from '../../service/lambda';


// Custom Components
import UploadImage from '../upload-image';
import TextInput from '../text-input';


//mintChannel
//const svgString = basicSvg.svgPartOne + basicSvg.svgPartTwo + basicSvg.svgPartThree
const steps = ['Create Profile', 'Set Details'];

function CreateProfile({store}) {
  let navigate = useNavigate();
  
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [validInputs, setValidInputs] = useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleCloseError = (event, reason) => {
    setErrorMessage('');
    setOpenError(false);
  };
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const [name, setName] = useState('');
  const handleNameChange = (event) => {
    setName(event.textVal);
  };

  const [author, setAuthor] = useState('');
  const handleAuthorChange = (event) => {
    setAuthor(event.target.value);
  };
  
  const [copyright, setCopyright] = useState('');
  const handleCopyrightChange = (event) => {
    setCopyright(event.target.value);
  };
  
  const [language, setLanguage] = useState('');
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  
  const [keywords, setKeywords] = useState('');
  const handleKeywordChange = (event) => {
    setKeywords(event.target.value);
  };

  const [website, setWebsite] = useState('');
  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
    if (event.target.value.length > 0) {
      setValidUrl(urlCheck(event.target.value));
    } else {
      setValidUrl(true);
    }
  };
  const [validUrl, setValidUrl] = useState(true);

  const [description, setDescription] = useState('');
  const handleDescriptionChange = (event) => {
    setDescription(event.textVal);
  };

  const [image, setImage] = useState('');

  const [, setMetadata] = useState('');
  const handleMetadataChange = (metadata) => {
    setMetadata(metadata);
  };
  
  

  /**/
  const mintProfile = async function() {
    try {
      setSubmissionLoading(true);

      if (name.length > 0) {
        const today = new Date();
        let customMapping = {
          properties:{
            createdDate : today.toUTCString(),
            author: author,
            language: language,
            copyright: copyright,
            website: website, 
            keywords: keywords + ' ' + name
          }
        };
        const metadata = await storeNFTMetadata(name, description, image, 'profile', customMapping);

        handleMetadataChange(metadata.ipnft);      
        await setProfileUri(metadata.ipnft, keywords + ' ' + name, store);

        setSubmissionLoading(false);
        setCreateModalOpen(false);
        syncEvent();
        navigate("/manage", { replace: true });
      } else {
        setSubmissionLoading(false);
      }
    } catch (err) {
      console.log(err);
      setSubmissionLoading(false);
    }
    
    
  }

  function mainImageChanged(event) {
    setImage(event.file);
  }

  function newModal() {
    setCreateModalOpen(true);
  }

  function closeNewModal() {
    setName('');
    setAuthor('');
    setCopyright('');
    setLanguage('');
    setWebsite('');
    setDescription('');
    setActiveStep(0);
    setCreateModalOpen(false);
  }

  // Stepper Functions
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const errorAction = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCloseError}>
        
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  
  useEffect(() => {
    if (name && description) {
      if (
        name.length > 0 && 
        description.length > 0 &&
        image !== '' && 
        (website === '' || validUrl) 
      ) {
        setValidInputs(true);
      } else {
        setValidInputs(false);
      }
    } else {setValidInputs(false);}
    
  },[name,author,copyright,language,website,validUrl,description,image, createModalOpen]);
  return (
    <>
      
        {/*<IconButton aria-label="addRecord" onClick={newChannel}>
          <AddIcon />
        </IconButton>*/}
        <Chip icon={<EditIcon />} label="Set Profile" onClick={newModal} ariant="outlined" clickable sx={{ mx: 4, mt:2,mb:4,fontSize:12}} />
        {/*<Button variant="contained" onClick={newModal} sx={{mx:'auto', display: 'block'}}>Set your Profile</Button>*/}
      
      <Dialog open={createModalOpen} onClose={closeNewModal}>
        <DialogTitle>
          Set Profile 
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeNewModal}
            sx={{float:'right'}}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Card sx={{ maxWidth: 1200 , minWidth: "25rem"}} className="transparent-background">
            
              {activeStep === 0 && 
                <div>
                  <UploadImage onImageChange={mainImageChanged}></UploadImage>
                  <TextInput onTextChange={handleNameChange} textType="h5" inputName="Name" inputType="text" defaultText="Name"></TextInput>
                  <TextInput onTextChange={handleDescriptionChange} textType="body2" inputName="About" inputType="textarea" defaultText="About You" sx={{mb:2}}></TextInput>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <Button onClick={handleNext}>
                      Next
                    </Button>
                  </Box>
                </div>
              }

              {activeStep === 1 && 
                <Box sx={{alignItems: 'flex-end'}}>
                  <Box maxWidth="max" sx={{ flexDirection: 'row', p: 8}}> 
                    <Box sx={{ justifyContent: 'center'}}>
                      <TextField
                        id="author"
                        variant="standard"
                        helperText="Author"
                        fullWidth
                        value={author}
                        onChange={handleAuthorChange}
                        sx={{display: 'block', mx: "auto", width:500}}
                      />  
                      <TextField
                        id="copyright"
                        variant="standard"
                        helperText="Copyright"
                        fullWidth
                        value={copyright}
                        onChange={handleCopyrightChange}
                        sx={{display: 'block', mx: "auto", width:500}}
                      /> 
                      <TextField
                        id="website"
                        variant="standard"
                        helperText="Website"
                        fullWidth
                        error={!validUrl}
                        value={website}
                        onChange={handleWebsiteChange}
                        sx={{display: 'block', mx: "auto", width:500}}
                      />                       
                       <TextField
                        id="keywords"
                        variant="standard"
                        helperText="Keywords"
                        fullWidth
                        value={keywords}
                        onChange={handleKeywordChange}
                        sx={{display: 'block', mx: "auto", width:500}}
                      />
                      <Select
                        id="language"
                        fullWidth
                        variant="standard"
                        value={language}
                        label="Language"                   
                        onChange={handleLanguageChange}
                        sx={{display: 'block', mx: "auto", width:500}}
                      >
                        <MenuItem value={'zh-cn'}>Mandarin</MenuItem>
                        <MenuItem value={'zh-hk'}>Cantonese</MenuItem>
                        <MenuItem value={'en-us'}>English - US</MenuItem>
                        <MenuItem value={'en-gb'}>English - UK</MenuItem>
                        <MenuItem value={'es'}>Spanish</MenuItem>
                        <MenuItem value={'fr'}>French</MenuItem>
                        <MenuItem value={'pt-br'}>Portuguese</MenuItem>
                        <MenuItem value={'ja'}>Japanese</MenuItem>
                        <MenuItem value={'ar'}>Arabic</MenuItem>
                        <MenuItem value={'de'}>German</MenuItem>
                        <MenuItem value={'sw'}>Swahili</MenuItem>
                      </Select>

                      <FormHelperText>Language</FormHelperText>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <LoadingButton
                      component="span"
                      loading={submissionLoading}
                      disabled={!validInputs}
                      variant="contained"
                      onClick={mintProfile}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </Box>
              }
          </Card>
        </DialogContent>
      </Dialog>
      
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={errorMessage}
        action={errorAction}
      />
      
    </>
  );
}

export default CreateProfile;


