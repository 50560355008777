import React, {useEffect, useState, useRef} from 'react';
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {BigNumber} from '@ethersproject/bignumber';
import { useEthers } from '@usedapp/core';
import Plyr from 'plyr-react';
import ReactPlayer from 'react-player';
import 'plyr-react/dist/plyr.css';

import PostMint from '../../../components/post-mint';
import { getCurrentChannelIndex, getChannelMetadata, getChannelPostContract, getPostUri } from '../../../service/worksManager.js';
import { cleanImageUrl, fetchMetadata } from "../../../service/utility";

function PostView({store}) {
  const { channelId, postId } = useParams();
  const { account, chainId } = useEthers();
  const [selectedMedia, setSelectedMedia] = React.useState('');
  const [selectedMediaType, setSelectedMediaType] = React.useState('');
  const [selectedMediaMime, setSelectedMediaMime] = React.useState('');
  const [selectedMediaData, setSelectedMediaData] = React.useState({});
  const [post, setPost] = React.useState({});
  const [postContract, setPostContract] = React.useState('');
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const componentIsMounted = useRef(true)
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    if (post?.parse_properties) {
      let parts = post.parse_properties[0].split("/");
      let mediaResult = parts[parts.length - 1];
      let mediatype = '';
      if (mediaResult === 'm4a') {
        mediatype = 'audio';
      } else {
        mediatype = 'video';
      }

      let media = {
        type: mediatype,
        title: post.name,
        sources: [
          {
            src: post.parse_properties[0],
            type: mediatype+'/'+mediaResult,
          }
        ]
      };
      if (media.sources.src !== '' && componentIsMounted.current) {
        setSelectedMedia(post.parse_properties[0]);
        setSelectedMediaType(mediatype);
        setSelectedMediaMime(mediatype+'/'+mediaResult);
        setSelectedMediaData(media);
        setDrawerOpen(true);
      }
      
    } 
  }, [post]);

  useEffect(() => {
    const getChannelPosts = async () => {
      try {
        const postContractAddress = await getChannelPostContract(channelId);
        if (componentIsMounted.current) setPostContract(postContractAddress);
      } catch (e) {
        console.log(e);
      }
    }; 
    getChannelPosts();
  }, [channelId, account, chainId]);

  useEffect(() => {
    const getChannelPosts = async () => {
      try {
        if (postId !== '' && postContract !== '') {
          let indexNum = BigNumber.from(postId);
          let postMetadataUri = await getPostUri(postContract, indexNum);
          if (postMetadataUri && postMetadataUri.length > 0) {
            let postMetadataResponse = await fetchMetadata(indexNum, postMetadataUri);
            if(componentIsMounted.current) setPost(postMetadataResponse);
          }
        }
      } catch (calloutError) {}
    }; 
    getChannelPosts();
  }, [postContract, postId, account, chainId]);
  

  return (
    <Grid container spacing={2} sx={{ minHeight: '80vh', pt: 10, py: 8, px:10, pl:25}}>
      {selectedMedia !== '' && 
        <Grid container spacing={2} sx={{m:10}}>
          <Grid item xs={12}>
            <Box sx={{width:"50vw", mx:'auto'}}>
              <ReactPlayer url={selectedMedia} light="false" controls={true} width="100%"/>
            </Box>          
          </Grid>
          <Grid item xs={12} sx={{}}>
            <Box sx={{width:"75vw", mx:'auto'}}>
              <Typography variant="h2" component="div" sx={{color: 'text.primary', textAlign: 'center', mb:0}} gutterBottom>
                {post.name}
              </Typography>
              <Typography variant="caption" component="div" sx={{color: 'text.primary', textAlign: 'center'}} gutterBottom>
                {post.properties?.createdDate}
              </Typography>
              <Typography variant="body2" component="div" sx={{color: 'text.primary', my: 10}} gutterBottom>
                {post.description}
              </Typography>
            </Box>
            <PostMint postContract={postContract} postId={postId} store={store}></PostMint>
          </Grid>
        </Grid>
      }
      {selectedMedia === '' && 
        <Grid container spacing={2} sx={{m:10}}>
          <Grid item xs={12} sx={{}}>
            <Box sx={{width:"75vw", mx:'auto'}}>
              <Typography variant="h2" component="div" sx={{color: 'text.primary', textAlign: 'center', mb:0}} gutterBottom>
                {post.name}
              </Typography>
              <Typography variant="caption" component="div" sx={{color: 'text.primary', textAlign: 'center'}} gutterBottom>
                {post.properties?.createdDate}
              </Typography>
              <Typography variant="body2" component="div" sx={{color: 'text.primary', my: 10}} gutterBottom>
                {post.description}
              </Typography>
            </Box>
            {//<PostMint postContract={postContract} postId={postId}></PostMint>
            }
          </Grid>
        </Grid>
      }
    </Grid>
  );
}

export default PostView;

/*
<Plyr
  source={selectedMediaData}
/>

<ReactPlayer url={selectedMedia} light="false"/>
          

*/