import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { getMembership, getMembershipList, getMembershipUri, mintChannelMembership} from '../../service/worksManager';
import { fetchMetadata } from '../../service/utility';
import LoadingButton from '@mui/lab/LoadingButton';
import { BigNumber } from 'ethers';
import { useEthers } from '@usedapp/core';
import matic_logo from '../../static/images/matic.png';
import ErrorToast from '../error-toast';


function MembershipButton(data) {
  const { account, chainId } = useEthers();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [channelId, ] = useState(data.channelId);
  const [userMembershipId, setUserMembershipId] = useState('');
  const [selectedMembership, setSelectedMembership] = useState('');
  const handleSelectedMembershipChange = (event) => {
    setIsDisabled(false);
    setSelectedMembership(event.target.value);
  };
  const [, setUserMembership] = useState({});
  const updateUserMembership = (metadataResponse) => {
    setUserMembership(metadataResponse);
    setSubmissionLoading(false);
  }
  const [membershipList, setMembershipList] = useState([]);
  const componentIsMounted = useRef(true);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    const getCMetadata = async () => {
      const membershipLists = await getMembershipList(channelId);
      let finalMembershipList = [];
      for (let membership of membershipLists) {
        let membershipResult = await getMembership(membership);
        if(membershipResult.eq(1)) {
          if (componentIsMounted.current) {
            setUserMembershipId(membership);
          }
          break;
        } else {
          let membershipMetadataUri = await getMembershipUri(membership);
          if (membershipMetadataUri) {
            let metadataResponse = await fetchMetadata(membership, membershipMetadataUri);
            (metadataResponse) ? finalMembershipList.push(metadataResponse) : console.log('error fetchMetadata');
          }
        }
      }
      if (userMembershipId === '' && componentIsMounted.current) {
        setMembershipList(finalMembershipList);
      }
    } 
    getCMetadata();   
  },[channelId, account, chainId]);

  useEffect(() => {
    const getCMetadata = async () => {
      if (userMembershipId !== '') {
        let membershipMetadataUri = await getMembershipUri(userMembershipId);
        if (membershipMetadataUri) {
          let metadataResponse = await fetchMetadata(userMembershipId, membershipMetadataUri);
          (metadataResponse) ? updateUserMembership(metadataResponse) : console.log('error fetchMetadata');
        }
      }
      
    } 
    getCMetadata();
  },[userMembershipId]);

  const mintMembership = async() => {
    try {
      setSubmissionLoading(true);
      const membershipId = BigNumber.from(selectedMembership.id);
      const membershipResponse = await mintChannelMembership(membershipId, selectedMembership.cost, data.store);
      if (membershipResponse === 'success') {
        setUserMembershipId(membershipId);
      }
    } catch (err) {
      setSubmissionLoading(false);
      setErrorMessage(err);
    }
    
  }

  return (
    <div style={{display:'contents'}}>
      
      {userMembershipId === '' && membershipList.length > 0 && 
        <div style={{display:'contents'}}>
          <Chip label="Subscribe" clickable onClick={handleClickOpen} sx={{mt:2,mb:4, mr: 4,fontSize:12}} />
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Subscribe</DialogTitle>
            <DialogContent>
              <Select
                labelId="membership-select-label"
                id="membership-select"
                value={selectedMembership}
                defaultValue=""
                label="Level"
                onChange={handleSelectedMembershipChange}
                sx={{minWidth:"10vw", my:10}}
              >
                {membershipList.length > 0 && 
                  membershipList.map(
                    (value) => {
                      return (
                        <MenuItem value={value} key={value.id}>
                          {value.name} - {value.cost}<img src={matic_logo} alt="MATIC" className="icon-image" style={{paddingLeft: '5px'}}/>
                        </MenuItem>
                      )
                    }
                  )
                }
              </Select>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <LoadingButton
                sx={{display: 'inline-block', width:100}}
                onClick={() => {
                  mintMembership();
                }}
                disabled={isDisabled}
                loading={submissionLoading}
              >
                Mint
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </div>
      }

      {userMembershipId !== '' && 
        <Chip icon={<CheckIcon className="white-text" />} label="Subscribed" sx={{mt:2,mb:4,fontSize:12, backgroundColor: theme.palette.info.dark,  color: theme.palette.common.white }} />
      }
      <ErrorToast errorMessage={errorMessage}></ErrorToast>
      
    </div>
  );
}

export default MembershipButton;

