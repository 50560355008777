import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import IconButton from '@mui/material/IconButton';
import { getProfileUri} from '../../service/worksManager';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import {ipfsGateway, truncateString} from '../../service/utility';
import { Typography, Button, Grid, Box } from '@mui/material';
const AvatarButton = (inputs) => {
  const { account, chainId } = useEthers();
  const [userMetadata, setUserMetadata] = useState({name: '', description: '', image: ''});  
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    //setChannelMetadata([]);
    //setChannelMap({});
    const getMetadata = async () => {
      try{
        const metadataUri = await getProfileUri(account);
        if (metadataUri && metadataUri !== 'ipfs:///metadata.json') {
          let convertedUri = metadataUri.replace('ipfs://', '');
          let metadataRequest = await fetch(ipfsGateway+convertedUri);
          let metadataResponse = await metadataRequest.json();
          metadataResponse["parse_image"] = cleanImageUrl(metadataResponse.image);
          console.log(metadataResponse);
          if (componentIsMounted.current) setUserMetadata(metadataResponse);
        }
      } catch (error) {
      }
      
    } 
    getMetadata();
  },[account, chainId]);

  const cleanImageUrl = (uri) => {
    return ipfsGateway+uri.replace('ipfs://', '');
  }

  return (
    <div style={{display:'inline-block', maxWidth: '100px'}}>
      {/*<div>{activateError}</div>*/}
      <Button component={Link} to={'/manage/'} sx={{display:'block'}}>
        {account && userMetadata.parse_image &&  
          <>
          <Grid container spacing={2} wrap={'nowrap'}>
            <Grid item xs={6}>
              <Avatar sx={{ mr:2, borderColor: 'primary.main', borderWidth: '2px', borderStyle: 'solid' }} src={userMetadata?.parse_image}></Avatar>
            </Grid>
            <Grid item xs={6} sx={{ml:6}}>
              <Typography variant="subtitle2" gutterBottom sx={{color: 'text.primary', fontSize: ".85rem", fontWeight: 600, textAlign:'left', mb:0, mt: 1}}>{truncateString(userMetadata.name.toUpperCase(), 20)}</Typography>
              <Typography variant="caption" sx={{color: 'text.primary', fontSize: ".65rem",textAlign:'left', mb:0}}>{truncateString(account.toUpperCase(), 10)}</Typography>              
            </Grid>
          </Grid>
            
              
            
          </>
        }

        {account && !userMetadata.parse_image &&  
          <Avatar sx={{ bgcolor: 'warning.main', mr:2 }}><PersonIcon /></Avatar>
        }
      </Button>

      
    </div>
  )
}

export default AvatarButton;