
import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PublicIcon from '@mui/icons-material/Public';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import BalanceIcon from '@mui/icons-material/Balance';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import MembershipButton from '../membership-button';
import SocialIcons from '../social-icons';
import ChannelBalance from '../channel-balance';
import {BigNumber} from '@ethersproject/bignumber';
import { getOwnerChannelIds } from '../../service/worksManager';


function ChannelMainDetail(input) {
  const [channelUrl, setChannelUrl] = useState('');
  const [isOwner, setIsOwner] = useState(false);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  useEffect(() => {
    const runChannelOwner = async () => {
      try {
        const ownerIds = await getOwnerChannelIds();
        if (Array.isArray(ownerIds)) {
          for (let oId of ownerIds) {
            if (BigNumber.from(input.ownerChannelId).eq(oId)) {
              if (componentIsMounted.current) {
                setIsOwner(true);
                setChannelUrl('/channels/'+BigNumber.from(input.ownerChannelId).toHexString());
              }
            }
          }
        }
      } catch (calloutError) {}
    } 
    runChannelOwner();
  }, [input.ownerChannelId])
  const Image = styled('img')({
    width: "-webkit-fill-available"
  });
  return (
    <div>
      {input.channelMetadata !== null && 
        <Box maxWidth="lg" sx={{ mb: 16, px: 16}}>
          <Box sx={{m:8, display:'block'}}>
            <Typography variant="h4" gutterBottom component="div" sx={{color: 'text.primary', my:0}}>{input?.channelMetadata?.name}</Typography> 
            <Typography variant="caption" display="block" gutterBottom sx={{color: 'text.secondary'}}>{input?.channelMetadata?.properties?.author}</Typography> 
            
            <Image src={input?.channelMetadata?.parse_image} alt='' />
          </Box>
          <Box sx={{m:8, mt:12, display:'block', verticalAlign: 'top'}}>
            { input?.channelId && 
              <MembershipButton channelId={input?.channelId} store={input.store}></MembershipButton>
            }
            <Chip icon={<PublicIcon />} target="_blank" label="Visit Website" component="a" href={input?.channelMetadata?.properties?.website} variant="outlined" clickable sx={{mt:2,mb:4,fontSize:12}} />
            { isOwner && 
              <>
                <ChannelBalance channelId={BigNumber.from(input?.ownerChannelId)}></ChannelBalance>
                <Chip icon={<ArtTrackIcon />} label="Channel Page" component={Link} to={channelUrl} variant="outlined" clickable sx={{mt:2,mb:4,fontSize:12}} />
              </>
            }
            
            <Typography variant="body2" gutterBottom sx={{color: 'text.primary', fontSize: "80%"}}>{input?.channelMetadata?.description}</Typography> 
            <SocialIcons 
              twitterUrl={input?.channelMetadata?.twitterUrl}
              emailUrl={input?.channelMetadata?.email}
              githubUrl={input?.channelMetadata?.githubUrl}
              websiteUrl={input?.channelMetadata?.website}
              instagramUrl={input?.channelMetadata?.instagramUrl}
            ></SocialIcons>
          </Box>
        </Box>
      }
    </div>
    
  );
}

export default ChannelMainDetail;