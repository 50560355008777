
import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { styled, makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Color from 'color';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import cx from 'clsx';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useBlogTextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/blog';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import { useTheme } from '@mui/material/styles';

import { getChannelMetadata, getProfileUri, getChannelOwner } from '../../service/worksManager';
import { cleanImageUrl, ipfsGateway } from '../../service/utility';


function ChannelCard({channelId, channelMetadata, channelType, channelSelection}) {
  const theme = useTheme();
  /*const useStyles = makeStyles(() => ({
    actionArea: {
      borderRadius: 16,
      transition: '0.2s',
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    card: ({ color }) => ({
      maxWidth: 230,
      borderRadius: 16,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: `0 6px 12px 0 ${Color(color)
          .rotate(-12)
          .darken(0.2)
          .fade(0.5)}`,
      },
    }),
    content: ({ color }) => {
      return {
        backgroundColor: color,
        padding: '1rem 1.5rem 1.5rem',
      };
    },
    title: {
      fontFamily: 'Montserrat',
      fontSize: '2rem',
      color: '#fff',
    },
    subtitle: {
      fontFamily: 'Montserrat',
      color: '#fff',
      opacity: 0.87,
      marginTop: '2rem',
      fontWeight: 500,
      fontSize: 14,
    },
  }));*/
  const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    root: {
      margin: 'auto',
      borderRadius: spacing(5), // 16px
      transition: '0.3s',
      //boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
      position: 'relative',
      maxWidth: 200,
      minWidth: 200,
      height: '40vh',
      marginLeft: 'auto',
      overflow: 'initial',
      background: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[900],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white,
      paddingBottom: spacing(2),
      /*[breakpoints.up('md')]: {
        flexDirection: 'row',
        paddingTop: spacing(2),
      },*/
    },
    media: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 0,
      height: '-webkit-fill-available',
      paddingBottom: '48%',
      //borderRadius: spacing(5),
      borderTopLeftRadius: spacing(5),
      borderTopRightRadius: spacing(5),
      transform: 'scaleX(1) translateY(-.1rem)',
      position: 'relative',
      [breakpoints.up('md')]: {
        width: '100%',
      },
      '&:after': {
        content: '" "',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        //backgroundImage: 'linear-gradient(147deg, #fe8a39 0%, #fd3838 74%)',
        borderRadius: spacing(2), // 16
        opacity: 0.5,
      },
    },
    content: {
      padding: 24,
      color: theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    },
    cta: {
      marginTop: 24,
      textTransform: 'initial',
      color: theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    },
    artistText: {
      color: theme.palette.grey[400],
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    artistSection: {
      display: 'grid',
    }, 
    channelSection: {
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.white,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  }));
  const useContentStyles = makeStyles(({ breakpoints, spacing }) => ({
    overline: {
      textTransform: 'uppercase',
      letterSpacing: '1px',
      fontSize: 12,
      marginBottom: '0.875em',
      display: 'inline-block',
      color: theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    },
    heading: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: '0.35em',
      color: theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    },
    body: {
      marginBottom: spacing(2),
      fontSize: '0.8rem',
      letterSpacing: '0.00938em',
      color: theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    },
  }));
  const styles = useStyles();
  /*const {
    button: buttonStyles,
    ...contentStyles
  } = useBlogTextInfoContentStyles();*/
  const contentStyles = useContentStyles();
  const shadowStyles = useOverShadowStyles();
  
  const [channelLink, setChannelLink] = useState("");
  const [metadata, setChannelMetadata] = useState({});
  const [artistId, setArtistId] = useState("");
  const [artistMetadata, setArtistMetadata] = useState({name: '', description: '', image: ''});  
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  
  useEffect(() => {
    //setChannelMetadata([]);
    //setChannelMap({});
    const getMetadata = async () => {
      try{
        const _artistId = await getChannelOwner(channelId); 
        setArtistId(_artistId);
        const metadataUri = await getProfileUri(_artistId);
        if (metadataUri && metadataUri !== 'ipfs:///metadata.json') {
          let convertedUri = metadataUri.replace('ipfs://', '');
          let metadataRequest = await fetch(ipfsGateway+convertedUri);
          let metadataResponse = await metadataRequest.json();
          metadataResponse["parse_image"] = await cleanImageUrl(metadataResponse.image);
          if (componentIsMounted.current) setArtistMetadata(metadataResponse);
        }
      } catch (error) {
        console.log(error);
      }      
    } 
    if (channelId) {
      getMetadata();      
    }
  },[channelId]);

  useEffect(() => {
    const getMetadata = async (channelId) => {
      const metadataUri = await getChannelMetadata(channelId);
      if (metadataUri && metadataUri !== 'ipfs:///metadata.json' && metadataUri !== ipfsGateway+'metadata.json') {
        let convertedUri = metadataUri.replace('ipfs://', '');
        let metadataRequest = await fetch(ipfsGateway+convertedUri);
        let metadataResponse = await metadataRequest.json();
        metadataResponse["parse_image"] = await cleanImageUrl(metadataResponse.image);
        if (componentIsMounted.current) setChannelMetadata(metadataResponse);
      }
    } 
    if (componentIsMounted.current) setChannelLink('/channels/'+channelId);
    if (channelMetadata && componentIsMounted.current) {
      setChannelMetadata(channelMetadata);
    } else if (channelId) {
      getMetadata(channelId);
    }
  }, [channelId, channelMetadata]);

  const selectCard = () => {
    channelSelection(channelMetadata)
  };

  const Image = styled('img')({
    "maxWidth": "85px", width: "-webkit-fill-available", borderRadius:'1rem'
  });

  const CustomCard = ({ classes, image, title, subtitle }) => {
    return (
      <CardActionArea className={classes.actionArea}>
        <Card className={classes.card}>
          <CardMedia component="img" height="250" image={image} />
          <CardContent className={classes.content}>
            <Typography className={classes.title} variant={'h2'}>
              {title}
            </Typography>
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    );
  };

  const BetterCard = ({classes, artistName, artistId, image, title, subtitle }) => {
    return (
      <Card className={cx(styles.root, shadowStyles.root)} sx={{
        height: channelType === "draft" ? '35vh !important' : 'inherit',
      }}>
        <CardMedia
          className={styles.media}
          image={image}
        >
          {artistMetadata.parse_image &&  
            <Avatar className="card-avatar" sx={{ mr:2, borderColor: 'warning.main', borderWidth: '2px', borderStyle: 'solid' }} src={artistMetadata?.parse_image}></Avatar>
          }

          {!artistMetadata.parse_image &&  
            <Avatar className="card-avatar" sx={{ bgcolor: 'warning.main', mr:2 }}><PersonIcon /></Avatar>            
          }
        </CardMedia>
        <CardContent className={styles.artistSection}>
          {/*<TextInfoContent
            classes={contentStyles}
            name={name}
            heading={title}
            body={subtitle}
          />*/}
          <Typography variant="subtitle1" gutterBottom component="div" className={styles.artistText}>
            {artistName !== '' && 
              <>{artistName}</>
            }

            {artistName === '' && 
              <>{artistId}</>              
            }
          </Typography>
          
          <Typography variant="h6" gutterBottom component="div" className={styles.channelSection}>
            {title}
          </Typography>
          
        </CardContent>
      </Card>
    );
  }

  return (
    <div style={{display: 'contents'}}>
      {metadata.name != null && channelType === null && 
        <Button component={Link} to={channelLink} variant="bare" sx={{textTransform:'none',backgroundColor: 'transparent !important'}}>
          <BetterCard
            classes={styles}
            title={metadata.name}
            subtitle={metadata.description}
            image={
              metadata.parse_image || metadata.image
            }
            artistName={
              artistMetadata.name
            }
            artistId={
              artistId
            }
          />
        </Button>
      }

      
      {metadata.name != null && channelType === 'draft' && 
        <Button onClick={selectCard} variant="bare" sx={{textTransform:'none',backgroundColor: 'transparent !important'}}>
          <BetterCard
            classes={styles}
            title={metadata.name}
            subtitle={metadata.description}
            image={
              metadata.parse_image || metadata.image
            }
            artistName={
              artistMetadata.name
            }
            artistId={
              artistId
            }
          />
        </Button>
      }
    </div>
    
  );
}

export default ChannelCard;

/*
<Box maxWidth="lg" sx={{mx: "auto", mb: 16, display: 'flex'}}>
  <Box sx={{m:8, display:'inline-block'}}>
    <Image src={channelMetadata?.parse_image} alt=''/>
    <Typography noWrap variant="h6" gutterBottom component="div" sx={{color: 'text.primary', my:0, fontSize:"80%"}}>{channelMetadata?.name}</Typography> 
    <Typography noWrap variant="caption" display="block" gutterBottom sx={{color: 'text.secondary'}}>{channelMetadata?.properties?.author}</Typography> 
  </Box>
</Box>
*/