import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import FormHelperText from '@mui/material/FormHelperText';
import DialogContent from '@mui/material/DialogContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DialogTitle from '@mui/material/DialogTitle';

//import { basicSvg } from '../../../../static/constants';
import LoadingButton from '@mui/lab/LoadingButton';
import {mintChannel} from '../../service/worksManager';
import {storeNFTMetadata, urlCheck} from '../../service/utility';
import {syncEvent} from '../../service/lambda';


// Custom Components
import UploadImage from '../upload-image';
import TextInput from '../text-input';
import ErrorToast from '../error-toast';


//mintChannel
//const svgString = basicSvg.svgPartOne + basicSvg.svgPartTwo + basicSvg.svgPartThree
const steps = ['Create NFT', 'Set Rights'];

function CreateChannel({store}) {
  let navigate = useNavigate();
  
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const [channelName, setChannelName] = useState('');
  const handleNameChange = (event) => {
    setChannelName(event.textVal);
  };

  const [channelAuthor, setChannelAuthor] = useState('');
  const handleAuthorChange = (event) => {
    setChannelAuthor(event.target.value);
  };
  
  const [channelCopyright, setChannelCopyright] = useState('');
  const handleCopyrightChange = (event) => {
    setChannelCopyright(event.target.value);
  };
  
  const [channelLanguage, setChannelLanguage] = useState('');
  const handleLanguageChange = (event) => {
    setChannelLanguage(event.target.value);
  };

  const [channelWebsite, setChannelWebsite] = useState('');
  const handleWebsiteChange = (event) => {
    setChannelWebsite(event.target.value);
    if (event.target.value.length > 0) {
      setValidUrl(urlCheck(event.target.value));
    } else {
      setValidUrl(true);
    }
  };
  const [validUrl, setValidUrl] = useState(true);

  const [channelDescription, setChannelDescription] = useState('');
  const handleDescriptionChange = (event) => {
    setChannelDescription(event.textVal);
  };

  const [channelTwitter, setChannelTwitter] = useState('');
  const handleTwitterChange = (event) => {
    setChannelTwitter(event.textVal);
  };
  
  const [channelEmail, setChannelEmail] = useState('');
  const handleEmailChange = (event) => {
    setChannelEmail(event.textVal);
  };
  
  const [channelInstagram, setChannelInstagram] = useState('');
  const handleInstagramChange = (event) => {
    setChannelInstagram(event.textVal);
  };

  const [channelImage, setChannelImage] = useState('');

  const [, setChannelMetadata] = useState('');
  const handleMetadataChange = (metadata) => {
    setChannelMetadata(metadata);
  };

  /**/
  const mintCreatorChannel = async function() {
    try {
      setSubmissionLoading(true);

      if (channelName.length > 0) {
        const today = new Date();
        let customMapping = {
          properties:{
            createdDate : today.toUTCString(),
            author: channelAuthor,
            language: channelLanguage,
            copyright: channelCopyright,
            website: channelWebsite,
            twitterUrl: channelTwitter,
            instagramUrl : channelInstagram,
            email: channelEmail
          }
        };
        const metadata = await storeNFTMetadata(channelName, channelDescription, channelImage, 'channel', customMapping);
        const keywords = channelName + ' ' + channelAuthor + ' ' + channelLanguage;
        handleMetadataChange(metadata.ipnft);      
        await mintChannel(metadata.ipnft, keywords, store);

        setSubmissionLoading(false);
        setCreateModalOpen(false);
        syncEvent();
        navigate("/manage", { replace: true });
      } else {
        setSubmissionLoading(false);
      }
    } catch (err) {
      console.log(err);
      setSubmissionLoading(false);
      setErrorMessage(err.message);
    }
  }

  function mainImageChanged(event) {
    setChannelImage(event.file);
  }

  function newChannel() {
    setCreateModalOpen(true);
  }

  function closeNewChannelModal() {
    setChannelName('');
    setChannelAuthor('');
    setChannelCopyright('');
    setChannelLanguage('');
    setChannelWebsite('');
    setChannelEmail('');
    setChannelTwitter('');
    setChannelInstagram('');
    setChannelDescription('');
    setActiveStep(0);
    setCreateModalOpen(false);
  }

  // Stepper Functions
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Box sx={{my: 0, mx:'auto'}}>
        {/*<IconButton aria-label="addRecord" onClick={newChannel}>
          <AddIcon />
        </IconButton>*/}
        <Button variant="contained" onClick={newChannel} sx={{
          mx:'auto', display: 'block', 
          borderRadius: "100rem", fontWeight:'600', 
          fontSize: '80%', textTransform: 'none'
        }}>Create New Channel</Button>
      </Box>
      <Dialog open={createModalOpen} onClose={closeNewChannelModal}>
        <DialogTitle>
          New Channel 
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeNewChannelModal}
            sx={{float:'right'}}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Card sx={{ maxWidth: 1200 , minWidth: "25rem"}} className="transparent-background">
            
              {activeStep === 0 && 
                <div>
                  <UploadImage onImageChange={mainImageChanged}></UploadImage>
                  <TextInput onTextChange={handleNameChange} textType="h5" inputName="ChannelName" inputType="text" defaultText="Enter Channel Name"></TextInput>
                  <TextInput onTextChange={handleDescriptionChange} textType="body2" inputName="Description" inputType="textarea" defaultText="Enter Description" sx={{mb:2}}></TextInput>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <Button onClick={handleNext}>
                      Next
                    </Button>
                  </Box>
                </div>
              }

              {activeStep === 1 && 
                <Box sx={{alignItems: 'flex-end'}}>
                  <Box maxWidth="max" sx={{ flexDirection: 'row', p: 8}}> 
                    <Box sx={{ justifyContent: 'center'}}>
                      <TextField
                        id="author"
                        helperText="Channel Author"
                        variant="standard"
                        fullWidth
                        value={channelAuthor}
                        onChange={handleAuthorChange}
                        sx={{display: 'block', mx: "auto", width:500}}
                      />  
                      <TextField
                        id="copyright"
                        helperText="Channel Copyright"
                        variant="standard"
                        fullWidth
                        value={channelCopyright}
                        onChange={handleCopyrightChange}
                        sx={{display: 'block', mx: "auto", width:500}}
                      /> 
                      <TextField
                        id="website"
                        helperText="Channel Website"
                        variant="standard"
                        fullWidth
                        error={!validUrl}
                        value={channelWebsite}
                        onChange={handleWebsiteChange}
                        sx={{display: 'block', mx: "auto", width:500}}
                      />
                       <TextField
                        id="twitter"
                        helperText="Twitter"
                        variant="standard"
                        fullWidth
                        value={channelTwitter}
                        onChange={handleTwitterChange}
                        sx={{display: 'block', mx: "auto", width:500}}
                      />    
                      <TextField
                       id="instagram"
                       helperText="Instagram"
                       variant="standard"
                       fullWidth
                       value={channelInstagram}
                       onChange={handleInstagramChange}
                       sx={{display: 'block', mx: "auto", width:500}}
                      />                       
                       <TextField
                        id="email"
                        helperText="Email"
                        variant="standard"
                        fullWidth
                        value={channelEmail}
                        onChange={handleEmailChange}
                        sx={{display: 'block', mx: "auto", width:500}}
                      />
                      <Select
                        id="language"
                        fullWidth
                        variant="standard"
                        value={channelLanguage}
                        label="Channel Language"
                        helperText="Channel Language"                        
                        onChange={handleLanguageChange}
                        sx={{display: 'block', mx: "auto", width:500}}
                      >
                        <MenuItem value={'zh-cn'}>Mandarin</MenuItem>
                        <MenuItem value={'zh-hk'}>Cantonese</MenuItem>
                        <MenuItem value={'en-us'}>English - US</MenuItem>
                        <MenuItem value={'en-gb'}>English - UK</MenuItem>
                        <MenuItem value={'es'}>Spanish</MenuItem>
                        <MenuItem value={'fr'}>French</MenuItem>
                        <MenuItem value={'pt-br'}>Portuguese</MenuItem>
                        <MenuItem value={'ja'}>Japanese</MenuItem>
                        <MenuItem value={'ar'}>Arabic</MenuItem>
                        <MenuItem value={'de'}>German</MenuItem>
                        <MenuItem value={'sw'}>Swahili</MenuItem>
                      </Select>

                      <FormHelperText>Channel Language</FormHelperText>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <LoadingButton
                      component="span"
                      loading={submissionLoading}
                      variant="contained"
                      onClick={mintCreatorChannel}
                    >
                      Create
                    </LoadingButton>
                  </Box>
                </Box>
              }
          </Card>
        </DialogContent>
      </Dialog>
      
      <ErrorToast errorMessage={errorMessage}></ErrorToast>
      
    </>
  );
}

export default CreateChannel;


