import React, { useEffect, useState, useRef } from 'react';
import List from '@mui/material/List';
import { Typography } from '@mui/material';
import { useEthers } from '@usedapp/core';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import ArtistFeedItem from '../../../components/artist-feed-item';
import { getProfileEvents } from '../../../service/worksManager';

function ArtistList () {
  const { account, chainId } = useEthers();
  const theme = useTheme();
  const [artistList, setArtistList] = useState([]);
  const componentIsMounted = useRef(true)
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => { 
    const getMetadata = async () => {
      try {
        const events = await getProfileEvents();
        let artists = [];
        for (let event of events) {
          let args = {};
          if (!artists.includes(event.sender)) {
            artists.push(event.sender);
          }
        }
        if (componentIsMounted.current) setArtistList(artists);
      } catch (calloutError) {}
    }    
    
    getMetadata();   
  }, [account, chainId]);

  return (
    <Box sx={{ width: '75vw', padding: 10}}>
      <Typography variant="h5" gutterBottom component="div" sx={{padding:10, color: theme.palette.text.primary, }}>Artists</Typography>
      <List sx={{ width: '100%', }}>
        {artistList.length > 0 && 
          artistList.map((artist) => {
            return <ArtistFeedItem id={artist} key={artist}></ArtistFeedItem>;
          })
        }
      </List>
    </Box>
  )
}

export default ArtistList;