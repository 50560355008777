import React, { useEffect, useState, useRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function ErrorToast(input) {
  const [openError, setOpenError] = React.useState(false);
  const [currentError, setCurrentError] = React.useState('');
  const handleCloseError = (event, reason) => {
    setOpenError(false);
    setCurrentError('');
  };  
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    if (input.errorMessage){
      console.log(input.errorMessage);
    }
    
    if (input.errorMessage !== currentError && input.errorMessage != null) {
      if(componentIsMounted.current) setCurrentError(input.errorMessage);
      if(componentIsMounted.current) setOpenError(true);
    }
  }, [input.errorMessage])

  const errorAction = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCloseError}>
        
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return ( 
    <Snackbar
      open={openError}
      autoHideDuration={6000}
      onClose={handleCloseError}
      message={currentError}
      action={errorAction}
    />
  )
};

export default ErrorToast;
