import env from "react-dotenv";
import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import { useContractCall } from "@usedapp/core";
import { styled, makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Color from 'color';
import NoSsr from '@material-ui/core/NoSsr';
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Link } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import { useTheme } from '@mui/material/styles';

import ChannelCard from '../channel-card';
import { getOwnerChannelIds, getArtistChannelIds, getChannelMetadata, getCurrentChannelIndex } from '../../service/worksManager';
import { cleanImageUrl, fetchMetadata } from "../../service/utility";

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    /*[breakpoints.up('md')]: {
      justifyContent: 'center',
    },*/
    boxShadow: 'none !important'
  },
}));

function ChannelCardList({type, artistId}) {
  const theme = useTheme();
  const { account, chainId } = useEthers();
  const gridStyles = useGridStyles();

  const columns = [
    {
      field: 'name',
      headerName: 'Channel Name',
      flex:2,
      editable: false,
      sortable: true
    },
    {
      field: 'description',
      headerName: 'Channel Description',
      flex:3,
      editable: false,
      sortable: false
    },
    {
      field: 'parse_image',
      headerName: 'Channel Image',
      flex:2,
      editable: false,
      sortable: false,
      renderCell: (params)=>{
        return (
            <img src={params.row.parse_image} alt='' style={{width: "-webkit-fill-available"}} />
        )
      }
    },
    {
      field: 'manageButton',
      renderHeader: () => (
        <IconButton color="primary" aria-label="refresh list" onClick={refreshList} sx={{mx:"auto", color: "text.primary"}}>
          <RefreshIcon />
        </IconButton>
      ),
      flex:1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        let channelLink = '/manage/'+params.row.id;
        let linkText = 'Manage';
        if (artistId) {
          linkText = 'Access';
        }

        if(theme.palette.mode === 'dark') {
          return <Button component={Link} to={channelLink} variant="outlined" color="primary" sx={{mx:"auto"}}>{linkText}</Button>;
        } else if (theme.palette.mode === 'light') {
          return <Button component={Link} to={channelLink} variant="contained" color="primary" sx={{mx:"auto", boxShadow: 'none'}}>{linkText}</Button>;
        }
      }
    }
  ];

  const [channelMetadata, setChannelMetadata] = useState([]);
  const updateChannelMetadata = (metadata) => {
    setChannelMetadata([...channelMetadata, metadata]);
  }
  const [channelMap, setChannelMap] = useState({});
  const updateChannelMap = (channel, metadata) => {
    let cm = [...channelMap];
    cm[channel] = metadata;
    setChannelMap(cm);
  }  
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    //setChannelMetadata([]);
    //setChannelMap({});
    const getChannelList = async () => {
      try {
        let channelVals;
        let currentIndex;
        let channelList = [];
        if (type === 'artist'&& artistId) {
          channelVals = await getArtistChannelIds(artistId);
        } else if (type === 'owner') {
          channelVals = await getOwnerChannelIds();
        } else if (type === 'all') {
          currentIndex = await getCurrentChannelIndex();
        }
        if (currentIndex) {
          for (let i = 0;i <= currentIndex.toNumber();i++) {
            const channel = currentIndex.sub(i);
            let channelMetadataUri = await getChannelMetadata(channel);
            if (channelMetadataUri && channelMetadataUri !== 'ipfs:///metadata.json') {
              let channelMetadataResponse = await fetchMetadata(channel, channelMetadataUri);
              //updateChannelMap(channel, channelMedataResponse);
              channelList.push(channelMetadataResponse);
            }
          }
        } else if (channelVals) {
          for (let channel of channelVals) {
            let channelMetadataUri = await getChannelMetadata(channel);
            if (channelMetadataUri) {
              let channelMetadataResponse = await fetchMetadata(channel, channelMetadataUri);
              //updateChannelMap(channel, channelMedataResponse);
              channelList.push(channelMetadataResponse);
            }
          }
        }

        if (componentIsMounted.current) setChannelMetadata(channelList);
      } catch (calloutError) {}
    } 
    getChannelList();
  },[account, chainId, artistId, type]);

  

  const refreshList = async () => {
    let channelVals;
    if (artistId) {
      channelVals = await getArtistChannelIds(artistId);
    } else {
      channelVals = await getOwnerChannelIds();
    }
    let channelList = [];
    if (channelVals) {
      for (let channel of channelVals) {
        let channelMetadataUri = await getChannelMetadata(channel);
        if (channelMetadataUri) {
          let channelMetadataResponse = await fetchMetadata(channel, channelMetadataUri);
          //updateChannelMap(channel, channelMedataResponse);
          channelList.push(channelMetadataResponse);
        }
      }
      setChannelMetadata(channelList);
    }
  };

  const listOfChannels = () => (
    <>
      { channelMetadata.length > 0 && 
        channelMetadata.map((channel) => {
          return (
            <Grid item key={channel.id}>
              <ChannelCard channelMetadata={channel} channelId={channel.id}></ChannelCard>
            </Grid>
          )        
        })
      }
    </>
  );

  return (
      <Box sx={{ width: '75vw', padding: 10}}>
        {type === 'all' &&         
          <Typography variant="h5" gutterBottom component="div" sx={{padding:10, color: theme.palette.text.primary, }}>Channels</Typography>
        }
        <Grid classes={gridStyles} container spacing={2} wrap={'nowrap'}>
          {listOfChannels()}
        </Grid>
      </Box>
  );
}

export default ChannelCardList;