
function Account() {
  return (
    <div >
      <header className="App-header">
        <p>
          Account
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default Account;
