import env from "react-dotenv";
import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import { useContractCall } from "@usedapp/core";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Link } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import { useTheme } from '@mui/material/styles';

import { getOwnerChannelIds, getArtistChannelIds, getChannelMetadata } from '../../../../service/worksManager';
import { cleanImageUrl, fetchMetadata } from "../../../../service/utility";

function ChannelList() {
  const theme = useTheme();
  const { account, chainId } = useEthers();
  const { artistId } = useParams();

  const columns = [
    {
      field: 'name',
      headerName: 'Channel Name',
      flex:2,
      editable: false,
      sortable: true
    },
    {
      field: 'description',
      headerName: 'Channel Description',
      flex:3,
      editable: false,
      sortable: false
    },
    {
      field: 'parse_image',
      headerName: 'Channel Image',
      flex:2,
      editable: false,
      sortable: false,
      renderCell: (params)=>{
        return (
            <img src={params.row.parse_image} alt='' style={{width: "-webkit-fill-available"}} />
        )
      }
    },
    {
      field: 'manageButton',
      renderHeader: () => (
        <IconButton color="primary" aria-label="refresh list" onClick={refreshList} sx={{mx:"auto", color: "text.primary"}}>
          <RefreshIcon />
        </IconButton>
      ),
      flex:1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        let channelLink = '/manage/'+params.row.id;
        let linkText = 'Manage';
        if (artistId) {
          linkText = 'Access';
        }

        if(theme.palette.mode === 'dark') {
          return <Button component={Link} to={channelLink} variant="outlined" color="primary" sx={{mx:"auto"}}>{linkText}</Button>;
        } else if (theme.palette.mode === 'light') {
          return <Button component={Link} to={channelLink} variant="contained" color="primary" sx={{mx:"auto", boxShadow: 'none'}}>{linkText}</Button>;
        }
      }
    }
  ];

  const [channelMetadata, setChannelMetadata] = useState([]);
  const updateChannelMetadata = (metadata) => {
    setChannelMetadata([...channelMetadata, metadata]);
  }
  const [channelMap, setChannelMap] = useState({});
  const updateChannelMap = (channel, metadata) => {
    let cm = [...channelMap];
    cm[channel] = metadata;
    setChannelMap(cm);
  }
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  

  useEffect(() => {
    //setChannelMetadata([]);
    //setChannelMap({});
    const getChannelList = async () => {
      try {
        let channelVals;
        if (artistId) {
          channelVals = await getArtistChannelIds(artistId);
        } else {
          channelVals = await getOwnerChannelIds();
        }
        
       
        let channelList = [];
        if (channelVals) {
          for (let channel of channelVals) {
            let channelMetadataUri = await getChannelMetadata(channel);
            if (channelMetadataUri) {
              let channelMetadataResponse = await fetchMetadata(channel, channelMetadataUri, false);
              //updateChannelMap(channel, channelMedataResponse);
              channelList.push(channelMetadataResponse);
            }
          }
          if (componentIsMounted.current) {
            setChannelMetadata(channelList);
          }          
        }
      } catch (calloutError) {}
    } 
    getChannelList();
  },[account, chainId, artistId]);

  

  const refreshList = async () => {
    let channelVals;
    if (artistId) {
      channelVals = await getArtistChannelIds(artistId);
    } else {
      channelVals = await getOwnerChannelIds();
    }
    let channelList = [];
    if (channelVals) {
      for (let channel of channelVals) {
        let channelMetadataUri = await getChannelMetadata(channel);
        if (channelMetadataUri) {
          let channelMetadataResponse = await fetchMetadata(channel, channelMetadataUri);
          //updateChannelMap(channel, channelMedataResponse);
          channelList.push(channelMetadataResponse);
        }
      }
      console.log(channelList);
      setChannelMetadata(channelList);
    }
  };

  return (
    <div style={{ height: ' 100vh'}}>
      {channelMetadata.length > 0 && 
        <div style={{ height: 400, width: '100%', paddingTop: '5vh'}}>
          <DataGrid
            rows={channelMetadata}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            disableColumnMenu
            sx={{mx:'auto', width: '80vw'}}
          />
        </div>

      }
      
    </div>
  );
}

export default ChannelList;