import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Link } from 'react-router-dom';
import { useEthers } from '@usedapp/core';

import { getChannelMetadata } from '../../service/worksManager';
import { fetchMetadata } from "../../service/utility";

function ChannelTable({channels}) {
  const { account, chainId } = useEthers();
  const columns = [
    {
      field: 'name',
      headerName: 'Channel Name',
      flex:2,
      editable: false,
      sortable: true
    },
    {
      field: 'description',
      headerName: 'Channel Description',
      flex:3,
      editable: false,
      sortable: false
    },
    {
      field: 'parse_image',
      headerName: 'Channel Image',
      flex:2,
      editable: false,
      sortable: false,
      renderCell: (params)=>{
        return (
            <img src={params.row.parse_image} alt='' style={{width: "-webkit-fill-available"}} />
        )
      }
    },
    {
      field: 'accessButton',
      renderHeader: () => (
        <IconButton color="primary" aria-label="refresh list" onClick={refreshList} sx={{mx:"auto", color: "text.primary"}}>
          <RefreshIcon />
        </IconButton>
      ),
      flex:1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        let channelLink = '/channels/'+params.row.id;
        return <Button component={Link} to={channelLink} variant="outlined" color="primary" sx={{mx:"auto"}}>Access</Button>
      }
    }
  ];
  const [channelMetadata, setChannelMetadata] = useState([]);  
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    //setChannelMetadata([]);
    //setChannelMap({});
    const getChannelList = async () => {
      try {
        let channelList = [];
        if (channels) {
          for (let channel of channels) {
            let channelMetadataUri = await getChannelMetadata(channel.channelId.toNumber());
            if (channelMetadataUri) {
              let channelMetadataResponse = await fetchMetadata(channel.channelId, channelMetadataUri);
              channelList.push(channelMetadataResponse);
            }
          }
          if(componentIsMounted.current) setChannelMetadata(channelList);
        }
      } catch (calloutError) {
        console.log(calloutError);
      }
    } 
    getChannelList();
  },[account, chainId, channels]);

  const refreshList = async () => {
    let channelList = [];
    if (channels) {
      for (let channel of channels) {
        let channelMetadataUri = await getChannelMetadata(channel.channelId.toNumber());
        if (channelMetadataUri) {
          let channelMetadataResponse = await fetchMetadata(channel.channelId, channelMetadataUri);
          //updateChannelMap(channel, channelMedataResponse);
          channelList.push(channelMetadataResponse);
        }
      }
      setChannelMetadata(channelList);
    }
  };

  return (
    <div style={{height: '25vh'}}>
      <div style={{height: '100%'}}>
        <DataGrid
          rows={channelMetadata}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          disableColumnMenu
          sx={{mx:'auto'}}
        />
      </div>
    </div>
  );
}

export default ChannelTable;