import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useEthers } from '@usedapp/core';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import HeaderSwitch from './header-switch.js';
import AvatarButton from './avatarButton.js';
import TransactionHandler from './transactionHandler';

export const AccountButton = ({store, ui, menuChange, updateTheme, setOpenDrawer}) => {
  const { account, error, activateBrowserWallet, chainId } = useEthers();
  const [menuType, ] = useState('artist');
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);  
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  
  const [, setActivateError] = useState('');
  useEffect(() => {
    if (error) {
      //setActivateError(error.message);
      if(error.name === 'UnsupportedChainIdError') {
        setErrorModalOpen(true);
      }
    } else if (String(chainId) !== '31337' && String(chainId) !== '80001' && account) {
      if (componentIsMounted.current) setErrorModalOpen(true);
    } else {
      if (componentIsMounted.current) setErrorModalOpen(false);
      //setActivateError('')
    }
  }, [error, chainId, account]);

  useEffect(() => {
    if (componentIsMounted.current) menuChange(menuType);
  }, [menuType, ui]);

  useEffect(() => {
    if (menuType !== ui) {
      //setMenuType(inputs.ui);
    }
    
  }, [ui, menuType]);


  const activate = async () => {
    setActivateError('')
    activateBrowserWallet()
    setErrorModalOpen(false);
  }

  function setTheme(value) {
    updateTheme(value);
  }

  const setQueue = async function() {
    //await inputs.setOpenDrawer(true);
    setOpenDrawer(uuidv4());
  }

  function doNotClose() {
    console.log('sorry you can\'t close this');
  }
  
  const queueButton = () => {
    return (
      <IconButton onClick={setQueue} variant="contained" sx={{mx: 2}} aria-label="delete">
        <HeadphonesIcon></HeadphonesIcon>
      </IconButton>
    );
  }

  return (
    <div style={{display:'inline-block'}}>
      {/*<div>{activateError}</div>*/}
      {account ? (
        <>         
          <TransactionHandler store={store}></TransactionHandler>
          {/*<HeaderSwitch switchType="artist" sx={{display: "contents"}} valueChange={menuChange} value={menuType}></HeaderSwitch>*/}
          {/*queueButton()*/}
          <HeaderSwitch switchType="material" sx={{display: "contents"}} valueChange={setTheme} value={menuType}></HeaderSwitch>
          
        </>
      ) : (
        <Button color="secondary" variant="contained"
          onClick={activate}
        >Connect</Button>
      )}

      <Dialog 
        open={errorModalOpen} 
        onClose={doNotClose}
        disableEscapeKeyDown={true}
        onBackdropClick={doNotClose}
      >
        <DialogContent>
          <Box sx={{ }} className="transparent-background">
            Connected to wrong network, please switch to Polygon Testnet.             
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}

