
import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PublicIcon from '@mui/icons-material/Public';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import PostMint from '../post-mint';
import ChannelValue from '../channel-value';
import PostItemDuration from '../post-item-duration';
import ErrorToast from '../error-toast';
import { useTheme } from '@mui/material/styles';
import { getPostUri } from '../../service/worksManager';
import { env } from '../../static/constants';
import OpenseaLogo from '../../static/images/opensea_logo.png';
import { createReceptionSVG, cleanImageUrl, truncateString, ipfsGateway, buildQueueRow } from '../../service/utility';
import { BigNumber } from 'ethers';

function PostFeedItem({store, channelId, itemMetadata, contractAddress, id}) {
  const [openseaUrl, setOpenseaUrl] = useState('');
  const [postId, setPostId] = useState('');
  const [placeholderImage, setPlaceholderImage] = useState("");
  const theme = useTheme();
  const [postLink, setPostLink] = useState("");
  const [isInQueue, setIsInQueue] = useState(false);
  const [ queue, setState ] = store.useState('queue') || null;
  const [metadata, setMetadata] = useState({name: '', description: '', image: ''});  
  const [fromNow, setFromNow] = useState('');
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  useEffect(() => {
    const getMetadata = async (pId, address) => {
      const metadataUri = await getPostUri(address, pId);
      if (metadataUri && metadataUri !== 'ipfs:///metadata.json' && metadataUri !== ipfsGateway+'metadata.json') {
        let convertedUri = metadataUri.replace('ipfs://', '');
        let metadataRequest = await fetch(ipfsGateway+convertedUri);
        let metadataResponse = await metadataRequest.json();
        metadataResponse["parse_image"] = await cleanImageUrl(metadataResponse.image);
        const _fromNow = moment(metadataResponse.properties.createdDate).fromNow();

        if (componentIsMounted.current) setFromNow(_fromNow);
        if (componentIsMounted.current) setMetadata(metadataResponse);
        if (componentIsMounted.current) setPostId(pId);
        if (componentIsMounted.current) setPostLink('/channels/'+channelId+'/'+pId);
        if (componentIsMounted.current) setOpenseaUrl((env.REACT_APP_NODE_ENV === 'local' ? env.REACT_APP_OPENSEA_TEST_URL : env.REACT_APP_OPENSEA_TEST_URL)+address+'/'+BigNumber.from(pId).toNumber())
        
      }
    } 
    
    if (componentIsMounted.current) setPlaceholderImage(URL.createObjectURL(createReceptionSVG('')));
    if (itemMetadata) {
      const _fromNow = moment(itemMetadata.properties.createdDate).fromNow();
      if (componentIsMounted.current) setFromNow(_fromNow);
      if (componentIsMounted.current) setMetadata(itemMetadata);
      if (componentIsMounted.current) setOpenseaUrl((env.REACT_APP_NODE_ENV === 'local' ? env.REACT_APP_OPENSEA_TEST_URL : env.REACT_APP_OPENSEA_TEST_URL)+contractAddress+'/'+BigNumber.from(itemMetadata.id).toNumber());
      if (componentIsMounted.current) setPostId(itemMetadata.id);
    } else if (id && contractAddress) {
      getMetadata(id, contractAddress);
    }
  }, [id, contractAddress, itemMetadata, channelId]);

  useEffect(() => {
    if (queue && queue.length > 0 &&  metadata.name !== '') {
      let queueRow = buildQueueRow(metadata);
      if (queueRow) {
        var compareArray = queue.filter(value => value.key === queueRow.key);
        if (compareArray.length > 0) {
          if (componentIsMounted.current) setIsInQueue(true);
        } else {
          if (componentIsMounted.current) setIsInQueue(false);
        }
      }
      
    }
      
  }, [queue]);

  useEffect(() => {
    //console.log(fromNow);
    //console.log(metadata);
  }, [fromNow])

  const addToQueue = () => {
    let queueRow = buildQueueRow(metadata);
    var compareArray = queue.filter(value => value.key === queueRow.key);
    var copyArray = JSON.parse(JSON.stringify(queue));
    if (compareArray.length === 0) {
      copyArray.push(queueRow);
      setState(copyArray);
      setIsInQueue(true);
    }
  } 

  const Image = styled('img')({
    "maxWidth": "85px", width: "-webkit-fill-available", borderRadius:'1rem'
  });
  return (
    <>
      {metadata &&       
        <ListItem alignItems="flex-start" sx={{ borderBottom: '1px solid', borderColor: 'text.disabled'}}>
            <Box sx={{m:8, display:'inline-block'}}>
              <Button
                size="small"
                aria-label="close"
                color="inherit"
                component={Link} 
                // ToDo: add specific link lookup when Artist detail page is made
                to={postLink}
              >
                <Image src={metadata?.parse_image} alt='' />
              </Button>
            </Box>
            <Box sx={{mx:8, mt:12, display:'inline-block', verticalAlign: 'top'}}>
              <ChannelValue channelId={metadata?.properties?.channelId} value="name" type="body2" color="text.primary"></ChannelValue>
              <Typography variant="caption" display="block" gutterBottom sx={{color: 'text.secondary'}}>{fromNow.toString()}</Typography> 
              <Typography variant="body1" gutterBottom component="div" sx={{color: 'text.primary', my:0, py:0}}>{metadata?.name}</Typography> 
              <Box sx={{color: 'text.primary', fontSize: "80%", my:0, py:0, lineHeight:0}}>{parse(sanitizeHtml(metadata?.description))}</Box>

              { isInQueue && 
                <PlaylistAddCheckIcon sx={{color: theme.palette.success.dark}}/>
              }

              { !isInQueue && metadata.parse_properties !== undefined && 
                <IconButton aria-label="inQueue" onClick={addToQueue}>
                  <PlaylistAddIcon sx={{color: theme.palette.info.dark}} />
                </IconButton>
              }

              {metadata.parse_properties !== undefined && 
                <></>
                //<PostItemDuration uri={metadata.parse_properties[0]}></PostItemDuration>
              }

              {metadata.parse_properties === undefined && 
                <PostMint postContract={contractAddress} postId={postId} channelId={channelId} store={store}></PostMint>
              }
              {
              //<Typography variant="body2" gutterBottom sx={{color: 'text.primary', fontSize: "80%"}}>{truncateString(metadata?.description,80)}</Typography> 
              }
            </Box>
            
            <Box sx={{m:8, mt:12, display:'block', ml:'auto', verticalAlign: 'top'}}>
              <a style={{float:'right'}} href={openseaUrl} target="_blank" rel="noreferrer">
                <Image src={OpenseaLogo} style={{maxWidth: '2rem'}}></Image>
              </a>
            </Box>
        </ListItem>
      }

      {!metadata && 
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={placeholderImage} />
          </ListItemAvatar>
          <ListItemText
            primary="No artist profile"
          />
        </ListItem>
      }

    </>
    
  );
}

export default PostFeedItem;