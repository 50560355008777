import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {Parser} from 'html-to-react';


function TextInput({onTextChange, textType, inputType, inputName, defaultText}) {
  const [textVal, setTextVal] = React.useState('');
  const [htmlVal, ] = React.useState("<p>"+defaultText+"</p>");
  const [, setCreateModalOpen] = React.useState(false);
  const htmlToReactParser = new Parser();
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  function updateText(event) {
    setTextVal(event.target.value);
  }

  function selectInput() {
    setCreateModalOpen(true);
  }
  
   useEffect(() => {
    if(componentIsMounted.current) onTextChange({textVal, inputName});
  },[textVal, inputName]);

  useEffect(() => {
    if(componentIsMounted.current) onTextChange({htmlVal, inputName});
  },[htmlVal, inputName]);

  let field;
  if (inputType === 'text') {
    field = <TextField
      id={inputName}
      label={inputName}
      variant="standard"
      fullWidth
      value={textVal}
      placeholder={defaultText}
      onChange={updateText}
      sx={{display: 'block', mx: "auto", width:500}}/>;
  } else if (inputType === 'rtf') {
    field = <Box sx={{width: "800px"}} ></Box>;
  } else if (inputType === 'textarea') {
    field = <TextField
      id={inputName}
      label={inputName}
      variant="standard"
      fullWidth
      multiline
      rows={4}
      value={textVal}
      placeholder={defaultText}
      onChange={updateText}
      sx={{display: 'block', mx: "auto", width:500, mb:4}}/>;
  }

  let textDisplay;
  if (inputType === 'text') {
    textDisplay = <Typography variant={textType} component="div" gutterBottom onClick={selectInput} sx={{mb:'2rem'}}>{textVal}</Typography>;
  } else if (inputType === 'rtf') {
    textDisplay = <div onClick={selectInput}>{htmlToReactParser.parse(htmlVal)}</div>;
  } else if (inputType === 'textarea') {
    textDisplay = <Typography variant={textType} component="div" gutterBottom onClick={selectInput} sx={{my:'2rem'}}>{textVal}</Typography>;
  }
  

  return (
    <div>
      {field}
    </div>
  )

}
export default TextInput;

/*

<strong>
  {textDisplay}
</strong>
<Dialog open={createModalOpen} onClose={closeInputModal}>
  <DialogContent>
    {field}
  </DialogContent>
  <DialogActions>
    <Button onClick={closeInputModal}>Ok</Button>
  </DialogActions>
</Dialog>
*/
