import env from "react-dotenv";
import { v4 as uuidv4 } from 'uuid';
import { basicSvg } from "../static/constants";
import { NFTStorage, File, Blob } from 'nft.storage';
import axios from 'axios';
const { ethereum } = window;
//Static References
const nftStorageAddress = process.env.REACT_APP_NFT_STORAGE_API_KEY;
export const ipfsGateway = process.env.REACT_APP_IPFS_GATEWAY || 'https://ipfs.io/ipfs/';
//const contract = new Contract(wethContractAddress, wethInterface);
const storageClient = new NFTStorage({ token: nftStorageAddress });


if (!String.format) {
  String.format = function(format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function(match, number) { 
      return typeof args[number] != 'undefined'
        ? args[number] 
        : match
      ;
    });
  };
}

export const formatString = function(format) {
  var args = Array.prototype.slice.call(arguments, 1);
  return format.replace(/{(\d+)}/g, function(match, number) { 
    return typeof args[number] != 'undefined'
      ? args[number] 
      : match
    ;
  });
  
}

export const storeNFTMetadata = async (name, description, image, mtype, mapping) => {
  let rawObj;
  if (image) {
    rawObj = {
      name: name,
      description: description,
      image: new File(
        [
          image
        ],
        'image',
        { type: image.type }
      )
    };
  } else {
    
    let svgString = basicSvg.svgPartOne + basicSvg.svgPartTwo + basicSvg.svgPartThree;
    const metadata1Image = String.format(svgString, name + ' '+ mtype);
    const metadata1Blob = new Blob([metadata1Image], {type: 'image/svg+xml'});
    rawObj = {
      name: name,
      description: description,
      image: metadata1Blob
    };
  }

  if (mapping) {
    for(let m in mapping) {
      rawObj[m] = mapping[m];
    }
  }
  
  let metadata = await storageClient.store(rawObj);
  return metadata;
}

export const createReceptionSVG = (name) => {
  let svgString = basicSvg.svgPartOne + basicSvg.svgPartTwo + basicSvg.svgPartThree;
  const metadata1Image = String.format(svgString, name);
  return new Blob([metadata1Image], {type: 'image/svg+xml'});;
}

export const storeRawMetadata = async (metadata) => {
  const cid = await storageClient.storeBlob(new Blob(metadata))
  return cid;
}

export const cleanImageUrl = async (uri) => {
  if (uri.includes('ipfs') && uri.includes('/image')) {
    const imageResponse = await fetch(ipfsGateway+uri.replace('ipfs://', ''));
    const image = await imageResponse.text();
    if(image.startsWith('data')) {
      return image;
    } else {
      return ipfsGateway+uri.replace('ipfs://', '');
    }
  } else if (uri.includes('ipfs')) {
    return ipfsGateway+uri.replace('ipfs://', '');
  } else {
    return uri;
  }  
}

export const fetchMetadata = async (token, metadataUri, notBigNum) => {
  let metadataResponse = null;
  if (metadataUri !== 'ipfs:///metadata.json') {
    let metadataUriComputed = '';
    if (metadataUri.includes('ipfs')) {
      metadataUriComputed = metadataUri.replace('ipfs://', '');
    } else {
      metadataUriComputed = metadataUri;
    }
    if (metadataUriComputed && !metadataUriComputed.includes('ipfs://')) {
      let metadataRequest = await fetch(ipfsGateway+metadataUriComputed);
      metadataResponse = await metadataRequest.json();
      if (!notBigNum) {
        metadataResponse["key"] = token.toHexString();
        metadataResponse["id"] = token.toHexString();
      } else {        
        metadataResponse["key"] = token;
        metadataResponse["id"] = token;
      }
      
      metadataResponse["parse_image"] = await cleanImageUrl(metadataResponse.image);
      try {
        if (metadataResponse.properties && metadataResponse.properties.files) {
          metadataResponse['parse_properties'] = [];
          for (let i = 0;i<metadataResponse.properties.files.length;i++) {
            metadataResponse['parse_properties'].push(await cleanImageUrl(metadataResponse.properties.files[i]));
          }
        }
      } catch (e) {
        console.log(e);
      }
      
    }
  } else {
    metadataResponse = {}
  }
  
  return metadataResponse;
}

export const fetchIPNS = async (metadataUri) => {
  let metadataResponse = null;
  if (metadataUri !== 'ipfs:///metadata.json') {
    if (metadataUri && !metadataUri.includes('ipns')) {
      let metadataRequest = await fetch("https://ipfs.io"+metadataUri);
      metadataResponse = await metadataRequest.json();      
    }
  } else {
    metadataResponse = {}
  }
  
  return metadataResponse;
}

export const urlCheck = (websiteUrl) => { 
  const urlRegEx = new RegExp(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/);
    return urlRegEx.test(String(websiteUrl).toLowerCase());
}

export const truncateString = (str, num) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...'
}

export const buildQueueRow = (payload) => {
  let queueRow = {
    name: payload.name,
    key: payload.key,
    image: payload.parse_image,
    description: payload.description
  };

  if (payload.file) {
    return payload;
  }

  if (payload.parse_properties) {
    queueRow.file = payload.parse_properties[0];
    queueRow.createdDate = payload.properties.createdDate;
    return queueRow;
  } else {
    return null;
  }
}



export const convertURIToImageData = (url) => {
  return new Promise((resolve, reject) => {
    if (!url) {
      return reject();
    }
    const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      let dataURL;
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL('png');
      resolve(dataURL);
    };

    img.src = url;
  });
}