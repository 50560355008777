import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import { useEthers } from '@usedapp/core';

import { getNewMessageEventsHistorical } from '../../service/worksManager';
import MessageFeedItem from '../message-feed-item';

function MessageFeedList() {
  const { account, chainId } = useEthers();
  const [, setSubmissionLoading] = useState(false);
  const [channelMessages, setChannelMessages] = useState([]);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  const getChannelMessages = async () => {
    try {
      const events = await getNewMessageEventsHistorical();
      let messageList = [];
      for (let e of events) {
        let res = JSON.parse(e.msg);
        res.id = e.key;
        res.channelId = e.channelId;
        messageList.push(res);
      }
      if (componentIsMounted.current) {
        setChannelMessages(messageList);
        setSubmissionLoading(false);
      }
    } catch (calloutError) {
      console.log(calloutError);
    }
    
  };

  useEffect(() => {
    getChannelMessages();
  },[account, chainId]);

  const listOfMessages = () => (
    <div style={{display: 'contents'}}>
      { 
        channelMessages.map((message) => {
          return (
            <div key={message.id} style={{display: 'contents'}}>
              <MessageFeedItem messageData={message} channelId={message.channelId}></MessageFeedItem>  
            </div>
          );
        })        
      }
    </div>
  );

  return (
    <Box sx={{m:8, pb:16}} >
      {channelMessages.length > 0 && 
        <>
          {listOfMessages()}
        </>
      }
    </Box>
  );
}

export default MessageFeedList;