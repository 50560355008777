import env from "react-dotenv";
import { ethers } from 'ethers';
import axios from 'axios';
const { ethereum } = window;

const eventQueryApi = process.env.REACT_APP_EVENT_QUERY_URL;
const userSettingApi = process.env.REACT_APP_USER_SETTING_URL;
const userSettingQueryApi = process.env.REACT_APP_USER_SETTING_QUERY_URL;

export const formatString = function(format) {
  var args = Array.prototype.slice.call(arguments, 1);
  return format.replace(/{(\d+)}/g, function(match, number) { 
    return typeof args[number] != 'undefined'
      ? args[number] 
      : match
    ;
  });
  
}

export const queryEvents = async (type, query) => {
  const reqUrl = formatString(eventQueryApi, type, query, Number(ethereum.chainId));
  let metadataRequest = await fetch(reqUrl);
  const response = await metadataRequest.json();  
  let results = [];
  for (let item of response.results) {
    item.key = item.transactionHash;
    results.push(item);
  }
  return results;
}

export const postUserSetting = async (setting) => {
  //console.log(setting);
  const response = await axios.post(userSettingApi, setting);
  //console.log(response);
  return response;
}

export const queryUserSetting = async (channelId, owner) => {
  const reqUrl = userSettingApi + '?ownerId='+owner+'&channelId='+channelId+'&chainId='+ Number(ethereum.chainId);
  //console.log('queryUserSettings');
  let metadataRequest = await fetch(reqUrl);
  //console.log(metadataRequest);
  const response = await metadataRequest.json();
  //console.log(response); 
  return response;
}

export const syncEvent = () => {
  axios.post(eventQueryApi, {});
}

export const buildUserSetting = (channelId, buzzsproutToken, buzzsproutChannelId, tiers, ownerId) => {
  const setting = {
    chainId: Number(ethereum.chainId),
    channelId: channelId.toHexString(),
    buzzsproutToken : buzzsproutToken,
    buzzsproutChannelId: buzzsproutChannelId,
    posts: [],
    lastModified: new Date().toUTCString(),
    tiers: tiers,
    ownerId: String(ownerId + Number(ethereum.chainId)),
    userAddress: ownerId
  };
  return setting;
}