
import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PublicIcon from '@mui/icons-material/Public';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { truncateString } from '../../service/utility';

function QueueFeedItem({item, selectedItem}) { 
    
  const theme = useTheme();
  const [isSelected, setIsSelected] = useState(false);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    console.log('selectedItem', selectedItem);
    console.log('item',item);
    if (item.key === selectedItem) {
      if (componentIsMounted.current) setIsSelected(true);
    } else {
      if (componentIsMounted.current) setIsSelected(false);
    }
  }, [item, selectedItem]);

  const Image = styled('img')({
    "maxWidth": "85px", width: "-webkit-fill-available", borderRadius:'1rem'
  });

  return (
    <div style={{display: 'contents'}}>
      {item !== null && 
        <ListItem alignItems="flex-start" sx={{ borderBottom: '1px solid', borderColor: 'text.disabled'}}>
          <Box sx={{m:8, display:'inline-block'}}>
            <Image src={item?.image} alt='' />            
          </Box>
          <Box sx={{m:8, mt:12, display:'inline-block', verticalAlign: 'top'}}>
            <Typography variant="h6" gutterBottom component="div" sx={{color: 'text.primary', my:0}}>{item?.name}</Typography> 
            <Typography variant="caption" display="block" gutterBottom sx={{color: 'text.secondary'}}>{item?.properties?.author}</Typography> 
            { isSelected && 
              <PlaylistPlayIcon sx={{color: theme.palette.success.dark}}/>
            }
            
            <Typography variant="body2" gutterBottom sx={{color: 'text.primary', fontSize: "80%"}}>{truncateString(item?.description,80)}</Typography> 
          </Box>

        </ListItem>    
      }
    </div>

    
    
  );
}

export default QueueFeedItem;

/*
<Box maxWidth="md" sx={{ 
  display: 'flex', 
  backgroundColor: isSelected ? theme.palette.grey[700] : 'transparent',

  }}>
  <Box sx={{m:8, display:'inline-block'}}>
    <Image src={item?.image} alt='trackText' sx={{display: 'inline-block', verticalAlign: 'top'}}/>
    <Box sx={{display: 'inline-block'}}>
      <Typography noWrap variant="h6" gutterBottom component="div" sx={{color: 'text.primary', my:0, fontSize:"80%"}}>{item?.name}</Typography> 
      <Typography noWrap variant="caption" display="block" gutterBottom sx={{color: 'text.secondary'}}>{item?.createdDate}</Typography>
    </Box> 
  </Box>
</Box>




*/