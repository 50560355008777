import React, { useEffect, useState, useRef } from 'react';
import List from '@mui/material/List';
import { Typography } from '@mui/material';
import { useEthers } from '@usedapp/core';
import PostFeedItem from '../../../../components/post-feed-item';
import { getPostEvents } from '../../../../service/worksManager';

function PostList (inputs) {
  const { account, chainId } = useEthers();
  const [postList, setPostList] = useState([]);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => { 
    const getMetadata = async () => {
      try {
        const events = await getPostEvents();
        let posts = [];
        for (let i of events) {
          if (!posts.find((post) => post.postId === i?.args?.postId && post.channelId === i?.args?.channelId)) {
            posts.push({postId : i?.args?.postId, postContractAddress: i?.args?.postContractAddress, channelId: i?.args?.channelId});
          }       
        }
        if (componentIsMounted.current) setPostList(posts);
      } catch (calloutError) {}
    }     
    getMetadata();   
  }, [account, chainId]);

  return (
    <>
      <List sx={{ width: '100%', }}>
        {postList.length > 0 && 
          postList.map((post) => {
            return <PostFeedItem id={post.postId} key={post.postId+post.postContractAddress} contractAddress={post.postContractAddress} channelId={post.channelId}></PostFeedItem>;
          })
        }
      </List>
    </>
  )
}

export default PostList;