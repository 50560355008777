import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { useContractCall } from "@usedapp/core";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import { storeNFTMetadata, fetchMetadata } from '../../../../../service/utility';
import CreateMessage from '../../../../../components/create-message';
import { useEthers } from '@usedapp/core';
import {BigNumber} from '@ethersproject/bignumber';

import { getMessageEventsByChannel, getMembershipUri, getChannelPostContract } from '../../../../../service/worksManager';

function MessageList({store}) {
  const { channelId } = useParams();
  const { account, chainId } = useEthers();
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [channelMessages, setChannelMessages] = useState([]);
  const [postContract, setPostContract] = useState('');
  const columns = [
    {
      field: 'createdDate',
      headerName: 'Date',
      flex: 3,
      editable: false,
      sortable: true
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 3,
      editable: false,
      sortable: false,
      type: 'singleSelect'
    },
    {
      field: 'isNew',
      flex: 2,
      editable: false,
      sortable: false,
      renderHeader: () => (
        <IconButton color="primary" aria-label="refresh list" onClick={refreshList} sx={{mx:"auto", color: "text.primary"}}>
          <RefreshIcon />
        </IconButton>
      ),
    }
  ];

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  
  useEffect(() => {
    getChannelMessages();
  },[postContract]);

  const getChannelMessages = async () => {
    if (channelId) {
      try {
        const events = await getMessageEventsByChannel(BigNumber.from(channelId).toNumber());
        let messageList = [];
        for (let e of events) {
          let res = JSON.parse(e.msg);
          res.id = e.key;
          res.channelId = e.channelId;
          messageList.push(res);
        }
        if (componentIsMounted.current) {
          setChannelMessages(messageList);
          setSubmissionLoading(false);
        }        
      } catch (calloutError) {
        console.log(calloutError);
      }
    }    
  };
  
  const getPostContract = async () => {
    if (channelId) {
      try {
        const postContractAddress = await getChannelPostContract(channelId);
        if (!BigNumber.from(postContractAddress).eq(0)) {
          setPostContract(postContractAddress);
        }        
      } catch (e) {
      }
    }
    
  }; 

  useEffect(() => {
    getPostContract();
  },[channelId, account, chainId]);

  const refreshList = async () => {
    setSubmissionLoading(true);
    setChannelMessages([]);
    getChannelMessages();
  };

  return (
    <Box sx={{m:8, pb:16}} >
      
      <Typography variant="h5" component="div" gutterBottom sx={{color: 'text.primary'}}>
        Messages
        <strong style={{ marginLeft: 'auto', float: "right"}}>
          <CreateMessage contractAddress={postContract} store={store}></CreateMessage>
        </strong>
      </Typography>
      <div style={{ display: 'flex', height: '23.25rem', width: '100%'}}>
        <DataGrid
          rows={channelMessages}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          disableColumnMenu
          sx={{}}
        />
      </div>
    </Box>
  );
}

export default MessageList;