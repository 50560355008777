import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Link } from 'react-router-dom';
import { useEthers } from '@usedapp/core';

import { getProfileUri } from '../../service/worksManager';
import { fetchMetadata } from "../../service/utility";

function ArtistTable({artists}) {
  const { account, chainId } = useEthers();
  const columns = [
    {
      field: 'name',
      headerName: 'Artist Name',
      flex:2,
      editable: false,
      sortable: true
    },
    {
      field: 'description',
      headerName: 'Artist Description',
      flex:3,
      editable: false,
      sortable: false
    },
    {
      field: 'accessButton',
      renderHeader: () => (
        <IconButton color="primary" aria-label="refresh list" onClick={refreshList} sx={{mx:"auto", color: "text.primary"}}>
          <RefreshIcon />
        </IconButton>
      ),
      flex:1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        let channelLink = '/artist/'+params.row.id;
        return <Button component={Link} to={channelLink} variant="outlined" color="primary" sx={{mx:"auto"}}>Access</Button>
      }
    }
  ];
  const [artistMetadata, setArtistMetadata] = useState([]);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {
    //setChannelMetadata([]);
    //setChannelMap({});
    const getChannelList = async () => {
      try {
        let artistList = [];
        if (artists) {
          for (let artist of artists) {
            let metadataUri = await getProfileUri(artist.sender);
            if (metadataUri) {
              let metadataResponse = await fetchMetadata(artist.sender, metadataUri, true);
              artistList.push(metadataResponse);
            }
          }
          if (componentIsMounted.current) setArtistMetadata(artistList);
        }
      } catch (calloutError) {
        console.log(calloutError);
      }
    } 
    getChannelList();
  },[account, chainId, artists]);

  const refreshList = async () => {
    let artistList = [];
    if (artists) {
      for (let artist of artists) {
        let metadataUri = await getProfileUri(artist.sender);
        if (metadataUri) {
          let metadataResponse = await fetchMetadata(artist.sender, metadataUri, true);
          artistList.push(metadataResponse);
        }
      }
      if (componentIsMounted.current) setArtistMetadata(artistList);
    }
  };

  return (
    <div style={{height: '25vh'}}>
      <div style={{height: '100%'}}>
        <DataGrid
          rows={artistMetadata}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          disableColumnMenu
          sx={{mx:'auto'}}
        />
      </div>
    </div>
  );
}

export default ArtistTable;