
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PublicIcon from '@mui/icons-material/Public';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { createReceptionSVG } from '../../service/utility';
import CreateProfile from '../create-profile';

function ArtistFeedDetail(input) {
  const { channelId, artistId } = useParams();
  const [placeholderImage, setPlaceholderImage] = useState("");  
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);
  useEffect(() => {
    if (componentIsMounted.current) setPlaceholderImage(URL.createObjectURL(createReceptionSVG('Create Profile')));
  }, [input, channelId])
  
  const Image = styled('img')({
    "maxHeight": "150px", width: "-webkit-fill-available", borderRadius:'1rem'
  });

  return (
    <div>
      {input.metadata && 
        <Box maxWidth="lg" sx={{ml: 24, mb: 16, display: 'flex'}}>
          <Box sx={{m:8, display:'inline-block'}}>
            <Image src={input?.metadata?.parse_image} alt='' />
          </Box>
          <Box sx={{m:8, mt:12, display:'inline-block', verticalAlign: 'top'}}>
            <Typography variant="h6" gutterBottom component="div" sx={{color: 'text.primary', my:0}}>{input?.metadata?.name}</Typography> 
            <Typography variant="caption" display="block" gutterBottom sx={{color: 'text.secondary'}}>{input?.metadata?.properties?.author}</Typography> 
            { input?.metadata?.properties?.website && 
              <Chip icon={<PublicIcon />} target="_blank" label="Visit Website" component="a" href={input?.metadata?.properties?.website} variant="outlined" clickable sx={{mt:2,mb:4,fontSize:12}} />
            }
            {!channelId && !artistId && 
              <CreateProfile store={input.store}></CreateProfile>
            }
            <Typography variant="body2" gutterBottom sx={{color: 'text.primary', fontSize: "80%"}}>{input?.metadata?.description}</Typography> 
          </Box>
        </Box>
      }

      {!input.metadata && 
        <Box maxWidth="lg" sx={{ml: 24, mb: 16, display: 'flex'}}>
          <Box sx={{m:8, display:'inline-block'}}>
            <Image src={placeholderImage} alt=''/>
          </Box>
          <Box sx={{m:8, mt:12, display:'inline-block', verticalAlign: 'top'}}>
            <Typography variant="h6" gutterBottom component="div" sx={{color: 'text.primary', my:0}}>No artist profile</Typography> 
            {!channelId && !artistId && 
              <CreateProfile store={input.store}></CreateProfile>
            }
          </Box>
        </Box>
      }

    </div>
    
  );
}

export default ArtistFeedDetail;