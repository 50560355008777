import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import PublicIcon from '@mui/icons-material/Public';
import InstagramIcon from '@mui/icons-material/Instagram';
import GavelIcon from '@mui/icons-material/Gavel';


function SocialIcons (inputs) {
  return (
    <>      
      <ButtonGroup variant="text" aria-label="text button group" sx={{mx:'auto'}}>
        { inputs.emailUrl && 
          <IconButton aria-label="email" href={inputs?.emailUrl} target="_blank">
            <EmailIcon />
          </IconButton>
        }
        { inputs.twitterUrl && 
          <IconButton aria-label="twitter" href={inputs?.twitterUrl} target="_blank">
            <TwitterIcon />
          </IconButton>
        }
        { inputs.githubUrl && 
          <IconButton aria-label="github" href={inputs?.githubUrl} target="_blank">
            <GitHubIcon />
          </IconButton>
        }        
        { inputs.instagramUrl && 
          <IconButton aria-label="instagram" href={inputs?.instagramUrl} target="_blank">
            <InstagramIcon />
          </IconButton>
        }
        { inputs.governanceUrl && 
          <IconButton aria-label="governance" href={inputs?.governanceUrl} target="_blank">
            <GavelIcon />
          </IconButton>
        }
        { inputs.websiteUrl && 
          <IconButton aria-label="website" href={inputs?.websiteUrl} target="_blank">
            <PublicIcon />
          </IconButton>
        }
      </ButtonGroup>
    </>
    
  )
}

export default SocialIcons;